const roundFormDefaultState = {
    name: '',
    type: 'round_robin',
    start_date: new Date(),
    end_date: new Date(),
    message: '',
    error: '',
};

export default (state = roundFormDefaultState, action) => {
    
    switch (action.type) {

        case 'ROUND_FORM_UPDATE':
            return {
                ...state,
                [action.field.prop]: action.field.value
            };

        case 'ROUND_FORM_ERROR':
            return {
                ...state,
                message: '',
                error: action.message
            }

        case 'ROUND_FORM_SUCCESS':
            return {
                ...state,
                error: '',
                message: action.message
            }

        case 'ROUND_FORM_RESET':
            return roundFormDefaultState;

        default:
            return state;
    }
};