import React from "react";
import { connect } from 'react-redux';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from '@material-ui/core/Icon';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import GridItem from "./common/GridItem";
import GridContainer from "./common/GridContainer";
import Table from "./common/Table";
import Card from "./common/Card";
import CardHeader from "./common/CardHeader";
import CardBody from "./common/CardBody";
import verifyLogin from '../utils/verifyLogin';
import { getLeagueFields, addField, updateField, removeField } from '../actions/fields';
import { fieldFormUpdate, fieldFormError, fieldFormReset } from '../actions/fieldForm';
import { openFieldsCreate, closeFieldsCreate, openFieldsUpdate, closeFieldsUpdate } from '../actions/modals';
import { setSelectedLeagueID } from '../actions/selectedLeague';


// import setAuthToken from '../utils/setAuthToken';

const styles = {
    cardCategoryWhite: {
      "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
      },
      "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
      }
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
      }
    }
};


class FieldsIndexPage extends React.Component {

    constructor(props) {
        super(props);

        this.toggleCreateModal = this.toggleCreateModal.bind(this);
        this.toggleUpdateModal = this.toggleUpdateModal.bind(this);
        this.submitCreateField = this.submitCreateField.bind(this);
        this.submitUpdateField = this.submitUpdateField.bind(this);

        this.state = {
            selectedField: {}
        };
    }

    componentDidMount() {

        // set the league sitename and use it to get the league        

        this.props.dispatch(setSelectedLeagueID(this.props.match.params.id));
        verifyLogin(this.props.history);

        this.props.dispatch(getLeagueFields(this.props.match.params.id));
    }

    componentDidUpdate() {
        verifyLogin(this.props.history);
    }

    toggleUpdateModal(isOpen, selectedField) {
        if (isOpen) {
            this.setState(() => ({
                selectedField
            }));
            this.props.dispatch(fieldFormReset());
            this.props.dispatch(fieldFormUpdate({ prop: 'name', value: selectedField.name }));
            this.props.dispatch(fieldFormUpdate({ prop: 'description', value: selectedField.description }));
            this.props.dispatch(openFieldsUpdate());
        }
        else {
            this.props.dispatch(closeFieldsUpdate());
        }
    }

    toggleCreateModal(isOpen) {
        if (isOpen) {
            this.props.dispatch(fieldFormReset());
            this.props.dispatch(openFieldsCreate());
        }
        else {
            this.props.dispatch(closeFieldsCreate());
        }
    }

    submitCreateField() {

        // perform some error checking
        if (this.props.fieldForm.name.trim() == "") {

            // required fields aren't present
            this.props.dispatch(fieldFormError("Please enter a field name."));
        }
        else {

            // add the field
            this.props.dispatch(addField(this.props.match.params.id, this.props.fieldForm));
        }
    }

    submitUpdateField() {

        // perform some error checking
        if (this.props.fieldForm.name.trim() == "") {

            // required fields aren't present
            this.props.dispatch(fieldFormError("Please enter a field name."));
        }
        else {

            // add the committee member
            this.props.dispatch(updateField(this.props.match.params.id, this.state.selectedField.id, this.props.fieldForm));
        }
    }

    removeField({ id }) {
        if (confirm("Are you sure you want to remove this field? This cannot be undone")) {
            this.props.dispatch(removeField(id, this.props.match.params.id));
        };
    }

    renderCreateModal() {
        const { classes } = this.props;

        return (
            <Modal
                aria-labelledby="field-add"
                aria-describedby="field-add"
                open={this.props.createModalOpen}
                onClose={() => this.toggleCreateModal(false)}
                className="modal_admin"
            >
                <div className="modal-member">
                    <Typography variant="h4" id="modal-title">
                        Add a Field
                    </Typography>
                    <Typography className="modal-description">
                        Please add the field name and description, than click 'Add Field'.
                    </Typography>

                    { this.props.fieldForm.error && (
                        <Typography className="modal-error">
                            { this.props.fieldForm.error }
                        </Typography>
                    )}

                    <TextField
                        id="create-field-title"
                        label="Name"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.fieldForm.name}
                        onChange={(e) => { this.props.dispatch(fieldFormUpdate({ prop: 'name', value: e.target.value })); }}
                        margin="normal"
                    />

                    <TextField
                        id="create-field-description"
                        label="Description"
                        className="modal-input"
                        fullWidth={true}
                        multiline={true}
                        value={this.props.fieldForm.description}
                        onChange={(e) => { this.props.dispatch(fieldFormUpdate({ prop: 'description', value: e.target.value })); }}
                        margin="normal"
                    />

                    <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.submitCreateField}>
                        Add Field
                    </Button>
                </div>
            </Modal>
        )
    }

    renderUpdateModal() {
        return (
            <Modal
                aria-labelledby="field-update"
                aria-describedby="field-update"
                open={this.props.updateModalOpen}
                onClose={() => this.toggleUpdateModal(false)}
                className="modal_admin"
            >
                <div className="modal-member">
                    <Typography variant="h4" className="modal-title">
                        Update Field
                    </Typography>
                    <Typography className="modal-description">
                        Please update the field name or description, and click the update button to continue.
                    </Typography>

                    { this.props.fieldForm.error && (
                        <Typography className="modal-error">
                            { this.props.fieldForm.error }
                        </Typography>
                    )}

                    { this.props.fieldForm.message && (
                        <Typography className="modal-success">
                            { this.props.fieldForm.message }
                        </Typography>
                    )}

                    <TextField
                        id="create-field-name"
                        label="Name"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.fieldForm.name}
                        onChange={(e) => { this.props.dispatch(fieldFormUpdate({ prop: 'name', value: e.target.value })); }}
                        margin="normal"
                    />

                    <TextField
                        id="create-field-description"
                        label="Description"
                        className="modal-input"
                        fullWidth={true}
                        multiline={true}
                        value={this.props.fieldForm.description}
                        onChange={(e) => { this.props.dispatch(fieldFormUpdate({ prop: 'description', value: e.target.value })); }}
                        margin="normal"
                    />

                    <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.submitUpdateField}>
                        Update Field
                    </Button>
                </div>
            </Modal>
        )
    }

    renderFields() {
        const { classes } = this.props;

        return (
            <GridContainer className="container-full">
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="primary" className="card-header">
                            <h4 className={classes.cardTitleWhite}>{this.props.league.name}</h4>
                            <p className={classes.cardCategoryWhite}>
                                League Field Management
                            </p>
                        </CardHeader>
                        <CardBody>
                            <Table
                            tableHeaderColor="primary"
                            tableHead={["Name", "Description", "Actions"]}
                            tableData={this.props.fields.map((theField) => [
                                theField.name, 
                                theField.description, 
                                (
                                    <div>
                                        <Icon className="fa fa-pen manager-table-icon manager-table-edit" onClick={() => this.toggleUpdateModal(true, theField)} />
                                        <Icon className="fa fa-trash manager-table-remove" onClick={() => this.removeField(theField)} />
                                    </div>
                                )
                            ])}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    }
    
    render() {
        return (
            <div>
                { this.renderFields() }

                <Fab className="btn-fab btn-add" onClick={() => this.toggleCreateModal(true)}>
                    <AddIcon />
                </Fab>

                {this.renderCreateModal()}
                {this.renderUpdateModal()}
            </div>
        );
    }
};

const mapStateToProps = (state) => {

    return {
        isAuthenticated: state.auth.isAuthenticated,
        loading: state.members.loading,
        fields: state.fields.fields,
        fieldForm: state.fieldForm,
        createModalOpen: state.modals.fieldsCreate,
        updateModalOpen: state.modals.fieldsUpdate,
        league: state.selectedLeague.league
    };
};

export default connect(mapStateToProps)(withStyles(styles)(FieldsIndexPage));
