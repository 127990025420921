import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
import { closeLeftSidebar } from '../../actions/sidebar';

class LeftSidebar extends React.Component {

    constructor(props) {
        super(props);

        this.closeSidebar = this.closeSidebar.bind(this);
    }

    closeSidebar() {
        this.props.dispatch(closeLeftSidebar());
    }

    render() {
        console.log(this.props);
        return (
            <Drawer open={this.props.isSidebarOpen} onClose={this.closeSidebar}>
                <div
                    tabIndex={0}
                    role="button"
                    onClick={this.closeSidebar}
                    onKeyDown={this.closeSidebar}
                    className="sidebar_left"
                >
                    <div className="list">

                        <List>
                            <ListItem button>
                                <ListItemText primary="League Administration" />
                            </ListItem>

                            <ListItem button onClick={() => { this.props.history.push('/'+this.props.league_id+'/members'); }}>
                                <ListItemIcon>
                                    <Icon className="fa fa-users" />
                                </ListItemIcon>
                                <ListItemText primary="Committee Members" />
                            </ListItem>

                            <ListItem button onClick={() => { this.props.history.push('/'+this.props.league_id+'/rules'); }}>
                                <ListItemIcon>
                                    <Icon className="fa fa-gavel" />
                                </ListItemIcon>
                                <ListItemText primary="Rules" />
                            </ListItem>

                            <ListItem button onClick={() => { this.props.history.push('/'+this.props.league_id+'/fields'); }}>
                                <ListItemIcon>
                                    <Icon className="fa fa-map-marker-alt" />
                                </ListItemIcon>
                                <ListItemText primary="Fields" />
                            </ListItem>
                        </List>

                        <Divider />

                        <List>
                            <ListItem button>
                                <ListItemText primary="Season Management" />
                            </ListItem>

                            <ListItem button onClick={() => { alert("Coming Soon!") }}>
                                <ListItemIcon>
                                    <Icon className="fa fa-cog" />
                                </ListItemIcon>
                                <ListItemText primary="Season Settings" />
                            </ListItem>

                            <ListItem button onClick={() => { this.props.history.push('/'+this.props.league_id+'/teams'); }}>
                                <ListItemIcon>
                                    <Icon className="fa fa-baseball-ball" />
                                </ListItemIcon>
                                <ListItemText primary="Divisions & Teams" />
                            </ListItem>

                            <ListItem button onClick={() => { this.props.history.push('/'+this.props.league_id+'/schedule'); }}>
                                <ListItemIcon>
                                    <Icon className="fa fa-calendar" />
                                </ListItemIcon>
                                <ListItemText primary="Schedule & Scores" />
                            </ListItem>

                            <ListItem button onClick={() => { this.props.history.push('/'+this.props.league_id+'/scores'); }}>
                                <ListItemIcon>
                                    <Icon className="fa fa-check" />
                                </ListItemIcon>
                                <ListItemText primary="Outstanding Scores" />
                            </ListItem>
                        </List>

                        <Divider />

                        <List>
                            <ListItem button>
                                <ListItemText primary="Playoff Management" />
                            </ListItem>

                            <ListItem button onClick={() => { alert("Coming Soon!") }}>
                                <ListItemIcon>
                                    <Icon className="fa fa-cog" />
                                </ListItemIcon>
                                <ListItemText primary="Playoff Settings" />
                            </ListItem>
                            
                            <ListItem button onClick={() => { this.props.history.push('/'+this.props.league_id+'/playoffs'); }}>
                                <ListItemIcon>
                                    <Icon className="fa fa-list" />
                                </ListItemIcon>
                                <ListItemText primary="Divisions & Rounds" />
                            </ListItem>

                            <ListItem button onClick={() => { this.props.history.push('/'+this.props.league_id+'/playoffs/schedule'); }}>
                                <ListItemIcon>
                                    <Icon className="fa fa-calendar" />
                                </ListItemIcon>
                                <ListItemText primary="Schedule & Scores" />
                            </ListItem>

                            <ListItem button onClick={() => { this.props.history.push('/'+this.props.league_id+'/playoffs/scores'); }}>
                                <ListItemIcon>
                                    <Icon className="fa fa-check" />
                                </ListItemIcon>
                                <ListItemText primary="Outstanding Scores" />
                            </ListItem>
                        </List>
                    </div>
                </div>
            </Drawer>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        isSidebarOpen: state.sidebar.left,
        league_id: state.selectedLeague.id
    };
};

export default withRouter(connect(mapStateToProps)(LeftSidebar));