export const ruleFormUpdate = ({ prop, value }) => {

    return {
        type: 'RULE_FORM_UPDATE',
        field: { prop, value }
    };
};

export const ruleFormError = (message) => {

    return {
        type: 'RULE_FORM_ERROR',
        message
    };
};

export const ruleFormSuccess = (message) => {

    return {
        type: 'RULE_FORM_SUCCESS',
        message
    };
};

export const ruleFormReset = () => {
    
    return {
        type: 'RULE_FORM_RESET',
    };
}