const ruleFormDefaultState = {
    parent_rule_id: 0,
    title: '',
    rule: '',
    message: '',
    error: '',
};

export default (state = ruleFormDefaultState, action) => {
    
    switch (action.type) {

        case 'RULE_FORM_UPDATE':
            return {
                ...state,
                [action.field.prop]: action.field.value
            };

        case 'RULE_FORM_ERROR':
            return {
                ...state,
                message: '',
                error: action.message
            }

        case 'RULE_FORM_SUCCESS':
            return {
                ...state,
                error: '',
                message: action.message
            }

        case 'RULE_FORM_RESET':
            return ruleFormDefaultState;

        default:
            return state;
    }
};