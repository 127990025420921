const fieldFormDefaultState = {
    name: '',
    description: '',
    message: '',
    error: '',
};

export default (state = fieldFormDefaultState, action) => {
    
    switch (action.type) {

        case 'FIELD_FORM_UPDATE':
            return {
                ...state,
                [action.field.prop]: action.field.value
            };

        case 'FIELD_FORM_ERROR':
            return {
                ...state,
                message: '',
                error: action.message
            }

        case 'FIELD_FORM_SUCCESS':
            return {
                ...state,
                error: '',
                message: action.message
            }

        case 'FIELD_FORM_RESET':
            return fieldFormDefaultState;

        default:
            return state;
    }
};