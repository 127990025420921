import React from "react";
import { connect } from 'react-redux';
import Calendar from 'react-calendar';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from '@material-ui/core/Icon';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';

import GridItem from "./common/GridItem";
import GridContainer from "./common/GridContainer";
import Table from "./common/Table";
import Card from "./common/Card";
import CardHeader from "./common/CardHeader";
import CardBody from "./common/CardBody";
import verifyLogin from '../utils/verifyLogin';
import { getFormattedGameTime, getPrettyTime } from '../utils/dateUtil';
import { getPlayoffGames, addPlayoffGames, updatePlayoffGame, updatePlayoffGameScore, removePlayoffGame } from '../actions/playoffs';
import { getLeagueTeams } from '../actions/teams';
import { getLeagueFields } from '../actions/fields';
import { gameFormUpdate, gameFormLoadDate, gameFormError, gameFormReset } from '../actions/gameForm';
import { openGamesCreate, closeGamesCreate, openGamesUpdate, closeGamesUpdate, openScoresUpdate, closeScoresUpdate } from '../actions/modals';
import { setSelectedLeagueID } from '../actions/selectedLeague';
import getDayGames from '../selectors/gamesByDay';

// import setAuthToken from '../utils/setAuthToken';
const format = 'h:mm a';

const styles = {
    cardCategoryWhite: {
      "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
      },
      "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
      }
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
      }
    }
};


class PlayoffScheduleIndexPage extends React.Component {

    constructor(props) {
        super(props);

        this.addGameRow = this.addGameRow.bind(this);
        this.updateGameRowItem = this.updateGameRowItem.bind(this);
        this.submitCreateGames = this.submitCreateGames.bind(this);
        this.submitUpdateGame = this.submitUpdateGame.bind(this);
        this.submitUpdateScore = this.submitUpdateScore.bind(this);

        this.state = {
            selectedDate: new Date(),
            selectedGame: {},
            gamesToAdd: [{
                away_team_id: 0,
                home_team_id: 0,
                field_id: 0,
                game_time: ''
            }]
        };
    }

    componentDidMount() {
        this.props.dispatch(setSelectedLeagueID(this.props.match.params.id));
        verifyLogin(this.props.history);

        this.props.dispatch(getPlayoffGames(this.props.match.params.id));

        // get the teams (if we need to)
        if (this.props.teams <= 0) {
            this.props.dispatch(getLeagueTeams(this.props.match.params.id));
        }

        // get the fields (if we need to)
        if (this.props.fields <= 0) {
            this.props.dispatch(getLeagueFields(this.props.match.params.id));
        }
    }

    componentDidUpdate() {
        verifyLogin(this.props.history);
    }

    addGameRow() {
        this.setState((prevState) => ({
            gamesToAdd: prevState.gamesToAdd.concat({
                away_team_id: 0,
                home_team_id: 0,
                field_id: 0,
                game_time: ''
            })
        }))
    }

    getTeamName(team_id) {
        let teamName = '';

        this.props.teams.forEach((theTeam) => {
            if (theTeam.id == team_id) {
                teamName = theTeam.name;
            }
        });

        return teamName;
    }

    getFieldName(field_id) {
        let fieldName = '';

        this.props.fields.forEach((theField) => {
            if (theField.id == field_id) {
                fieldName = theField.name;
            }
        });

        return fieldName;
    }

    updateGameRowItem(idx, type, value) {

        this.setState((prevState) => ({
            gamesToAdd: prevState.gamesToAdd.map((theGame, index) => {
                if (index == idx) {
                    return {
                        ...theGame,
                        [type]: value
                    };
                }
                else {
                    return theGame;
                }
            })
        }))
    }

    toggleUpdateModal(isOpen, selectedGame) {
        if (isOpen) {
            this.setState(() => ({
                selectedGame
            }));
            this.props.dispatch(gameFormReset());
            this.props.dispatch(gameFormUpdate({ prop: 'away_team_id', value: selectedGame.away_team_id }));
            this.props.dispatch(gameFormUpdate({ prop: 'home_team_id', value: selectedGame.home_team_id }));
            this.props.dispatch(gameFormUpdate({ prop: 'field_id', value: selectedGame.field_id }));
            // this.props.dispatch(gameFormUpdate({ prop: 'game_time', value: selectedGame.game_time }));
            this.props.dispatch(gameFormLoadDate(selectedGame.game_time));
            this.props.dispatch(openGamesUpdate());
        }
        else {
            this.props.dispatch(closeGamesUpdate());
        }
    }

    toggleUpdateScoreModal(isOpen, selectedGame) {
        if (isOpen) {
            this.setState(() => ({
                selectedGame
            }));
            this.props.dispatch(gameFormReset());
            this.props.dispatch(gameFormUpdate({ prop: 'away_score', value: selectedGame.away_score }));
            this.props.dispatch(gameFormUpdate({ prop: 'home_score', value: selectedGame.home_score }));
            this.props.dispatch(gameFormUpdate({ prop: 'away_team_id', value: selectedGame.away_team_id }));
            this.props.dispatch(gameFormUpdate({ prop: 'home_team_id', value: selectedGame.home_team_id }));
            this.props.dispatch(gameFormUpdate({ prop: 'is_away_forfeit', value: selectedGame.is_away_forfeit }));
            this.props.dispatch(gameFormUpdate({ prop: 'is_home_forfeit', value: selectedGame.is_home_forfeit }));
            this.props.dispatch(openScoresUpdate());
        }
        else {
            this.props.dispatch(closeScoresUpdate());
        }
    }

    toggleCreateModal(isOpen) {
        if (isOpen) {
            this.props.dispatch(openGamesCreate());
        }
        else {
            this.props.dispatch(closeGamesCreate());
        }
    }

    submitCreateGames() {

        let incompleteGames = false,
            invalidTeams = false;

        const prunedGames = this.state.gamesToAdd.filter((theGame) => {
            return (theGame.away_team_id != 0 || theGame.home_team_id != 0 || theGame.field_id != 0 || theGame.game_time != '' );
        })

        // if there are no pruned games, end back an error
        if (prunedGames.length <= 0) {
            this.props.dispatch(gameFormError("Please enter at least one game"));
        }
        else {
            prunedGames.forEach((theGame) => {
                if ((theGame.away_team_id == 0 || theGame.home_team_id == 0 || theGame.field_id == 0 || theGame.game_time == '' )) {
                    incompleteGames = true;
                }

                if (theGame.away_team_id == theGame.home_team_id) {
                    invalidTeams = true;
                }
            });
    
            if (incompleteGames) {
                
                // kick back an error that there are incomplete games entered.
                this.props.dispatch(gameFormError("You have incomplete games entered. Please ensure that all values are filled for each game."));
                this.setState((prevState) => ({
                    gamesToAdd: prunedGames
                }));
            }
            else if (invalidTeams) {

                // kick back an error that there are incomplete games entered.
                this.props.dispatch(gameFormError("You have the same team playing against itself in at least one game. Please fix this and re-submit."));
                this.setState((prevState) => ({
                    gamesToAdd: prunedGames
                }));
            }
            else {
    
                // add the games
                console.log(prunedGames);
                this.props.dispatch(addPlayoffGames(this.props.match.params.id, this.state.selectedDate, { games: prunedGames}));
            }
        }
    }

    submitUpdateGame() {

        // perform some error checking
        if (this.props.gameForm.home_team_id == 0 || this.props.gameForm.away_team_id == 0 || this.props.gameForm.field_id == 0) {

            // required fields aren't present
            this.props.dispatch(fieldFormError("Please enter all information before proceeding"));
        }
        else {

            // update the schedule game
            this.props.dispatch(updatePlayoffGame(this.props.match.params.id, this.state.selectedGame.id, this.props.gameForm));
        }
    }

    submitUpdateScore() {

        // update the schedule game
        this.props.dispatch(updatePlayoffGameScore(this.props.match.params.id, this.state.selectedGame.id, this.props.gameForm));
    }

    removeGame({ id }) {
        if (confirm("Are you sure you want to remove this game from the schedule? This cannot be undone")) {
            this.props.dispatch(removePlayoffGame(id, this.props.match.params.id));
        };
    }

    removeAddGameRow(index) {
        if (this.state.gamesToAdd.length > 1) {
            this.setState((prevState) => ({
                gamesToAdd: prevState.gamesToAdd.filter((theGame, theIndex) => theIndex != index)
            }));
        }
    }

    renderCreateModal() {
        const { classes } = this.props;

        return (
            <Modal
                aria-labelledby="games-add"
                aria-describedby="games-add"
                open={this.props.createModalOpen}
                onClose={() => this.toggleCreateModal(false)}
                className="modal_admin"
            >
                <div className="modal-member">
                    <Typography variant="h4" className="modal-title">
                        Add Games to the Schedule
                    </Typography>
                    <Typography className="modal-description">
                        Please add the home team, away team, field, and time of the games that you want to add, and click 'Submit' to add them. Click 'Add another game' to add multiple games at once.
                    </Typography>

                    { this.props.gameForm.error && (
                        <Typography className="modal-error">
                            { this.props.gameForm.error }
                        </Typography>
                    )}

                    {this.state.gamesToAdd.map((theGame, index) => {
                        return (
                            <GridContainer key={theGame.id} className="container-full">
                                <GridItem xs={3} sm={3} md={3}>
                                    <InputLabel htmlFor={"away_team_id_"+index}>Away Team</InputLabel>
                                    <Select
                                        value={theGame.away_team_id}
                                        onChange={(e) => { this.updateGameRowItem(index, 'away_team_id', e.target.value ); }}
                                        fullWidth={true}
                                        inputProps={{
                                            name: "away_team_id_"+index,
                                            id: "away_team_id_"+index
                                        }}
                                    >
                                        {this.props.teams.map((theTeam) => <MenuItem key={theTeam.id} value={theTeam.id}>{theTeam.name}</MenuItem>)}
                                    </Select>
                                </GridItem>
                                <GridItem xs={3} sm={3} md={3}>
                                    <InputLabel htmlFor={"home_team_id_"+index}>Home Team</InputLabel>
                                    <Select
                                        value={theGame.home_team_id}
                                        onChange={(e) => { this.updateGameRowItem(index, 'home_team_id', e.target.value ); }}
                                        fullWidth={true}
                                        inputProps={{
                                            name: "home_team_id_"+index,
                                            id: "home_team_id_"+index
                                        }}
                                    >
                                        {this.props.teams.map((theTeam) => <MenuItem key={theTeam.id} value={theTeam.id}>{theTeam.name}</MenuItem>)}
                                    </Select>
                                </GridItem>
                                <GridItem xs={2} sm={2} md={2}>
                                    <InputLabel htmlFor={"field_id_"+index}>Field</InputLabel>
                                    <Select
                                        value={theGame.field_id}
                                        onChange={(e) => { this.updateGameRowItem(index, 'field_id', e.target.value ); }}
                                        fullWidth={true}
                                        inputProps={{
                                            name: "field_id_"+index,
                                            id: "field_id_"+index
                                        }}
                                    >
                                        {this.props.fields.map((theField) => <MenuItem key={theField.id} value={theField.id}>{theField.name}</MenuItem>)}
                                    </Select>
                                </GridItem>
                                <GridItem xs={3} sm={3} md={3}>
                                    <InputLabel htmlFor={"game_time_"+index}>Game Time</InputLabel>
                                    <TimePicker
                                        showSecond={false}
                                        className="modal-input"
                                        onChange={(theDate) => { this.updateGameRowItem(index, 'game_time', theDate ); }}
                                        format={format}
                                        use12Hours
                                        inputReadOnly
                                    />
                                </GridItem>
                                <GridItem xs={1} sm={1} md={1}>
                                    <InputLabel htmlFor={"field_id_"+index}>&nbsp;</InputLabel>
                                    <Icon 
                                        onClick={() => { this.removeAddGameRow(index); }} 
                                        className="fa fa-trash manager-table-remove" 
                                    />
                                </GridItem>
                            </GridContainer>
                        );
                    })}

                    <Fab size="small" color="primary" className="fab-add-game" onClick={this.addGameRow}>
                        <AddIcon />
                    </Fab>

                    <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.submitCreateGames}>
                        Submit New Games
                    </Button>
                </div>
            </Modal>
        )
    }

    renderUpdateModal() {
        return (
            <Modal
                aria-labelledby="game-update"
                aria-describedby="game-update"
                open={this.props.updateModalOpen}
                onClose={() => this.toggleUpdateModal(false)}
                className="modal_admin"
            >
                <div className="modal-member">
                    <Typography variant="h4" className="modal-title">
                        Update Scheduled Game
                    </Typography>
                    <Typography className="modal-description">
                        Please update the information that you would like to, and click 'Update' to update the game information.
                    </Typography>

                    { this.props.gameForm.error && (
                        <Typography className="modal-error">
                            { this.props.gameForm.error }
                        </Typography>
                    )}

                    { this.props.gameForm.message && (
                        <Typography className="modal-success">
                            { this.props.gameForm.message }
                        </Typography>
                    )}

                    <Typography variant="h5" className="modal-title modal-input-label">
                        Teams
                    </Typography>
                    <GridContainer>
                        <GridItem xs={12} sm={5} md={5}>
                            <InputLabel htmlFor={"game_update_away_team_id"}>Away Team</InputLabel>
                            <Select
                                value={this.props.gameForm.away_team_id}
                                onChange={(e) => { this.props.dispatch(gameFormUpdate({ prop: 'away_team_id', value: e.target.value })); }}
                                fullWidth={true}
                                inputProps={{
                                    name: "game_update_away_team_id",
                                    id: "game_update_away_team_id"
                                }}
                            >
                                {this.props.teams.map((theTeam) => <MenuItem key={theTeam.id} value={theTeam.id}>{theTeam.name}</MenuItem>)}
                            </Select>
                        </GridItem>
                        <GridItem xs={12} sm={2} md={2}>
                                <p>VS.</p>
                        </GridItem>
                        <GridItem xs={12} sm={5} md={5}>
                            <InputLabel htmlFor={"game_update_away_team_id"}>Home Team</InputLabel>
                            <Select
                                value={this.props.gameForm.home_team_id}
                                onChange={(e) => { this.props.dispatch(gameFormUpdate({ prop: 'home_team_id', value: e.target.value })); }}
                                fullWidth={true}
                                inputProps={{
                                    name: "game_update_home_team_id",
                                    id: "game_update_home_team_id"
                                }}
                            >
                                {this.props.teams.map((theTeam) => <MenuItem key={theTeam.id} value={theTeam.id}>{theTeam.name}</MenuItem>)}
                            </Select>
                        </GridItem>
                    </GridContainer>

                    <Typography variant="h5" className="modal-title modal-input-label">
                        Game Time
                    </Typography>
                    <GridContainer className="container-full">
                        <GridItem xs={12} sm={6} md={6}>
                            <DatePicker
                                selected={this.props.gameForm.game_time}
                                onChange={(value) => { this.props.dispatch(gameFormUpdate({ prop: 'game_time', value })); }}
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                dateFormat="MMMM d, yyyy h:mm aa"
                                timeCaption="time"
                                className="modal-datepicker"
                            />
                        </GridItem>
                    </GridContainer>

                    <Typography variant="h5" className="modal-title modal-input-label">
                        Field
                    </Typography>

                    <GridContainer className="container-full">
                        <GridItem xs={12} sm={12} md={12}>
                            <InputLabel htmlFor={"update_game_field"}>Game Field</InputLabel>
                            <Select
                                value={this.props.gameForm.field_id}
                                onChange={(e) => { this.props.dispatch(gameFormUpdate({ prop: 'field_id', value: e.target.value })); }}
                                fullWidth={true}
                                inputProps={{
                                    name: "update_game_field",
                                    id: "update_game_field"
                                }}
                            >
                                {this.props.fields.map((theField) => <MenuItem key={theField.id} value={theField.id}>{theField.name}</MenuItem>)}
                            </Select>
                        </GridItem>
                    </GridContainer>

                    <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.submitUpdateGame}>
                        Update Game
                    </Button>
                </div>
            </Modal>
        )
    }

    renderUpdateScoreModal() {
        return (
            <Modal
                aria-labelledby="score-update"
                aria-describedby="score-update"
                open={this.props.updateScoreModalOpen}
                onClose={() => this.toggleUpdateScoreModal(false)}
                className="modal_admin"
            >
                <div className="modal-member">
                    <Typography variant="h4" className="modal-title">
                        Update Game Score
                    </Typography>
                    <Typography className="modal-description">
                        Please update the home and away score, and click 'Update' to update the game score!
                    </Typography>

                    { this.props.gameForm.error && (
                        <Typography className="modal-error">
                            { this.props.gameForm.error }
                        </Typography>
                    )}

                    { this.props.gameForm.message && (
                        <Typography className="modal-success">
                            { this.props.gameForm.message }
                        </Typography>
                    )}

                    <Typography variant="h5" className="modal-title">
                        Teams
                    </Typography>
                    <GridContainer>
                        <GridItem xs={12} sm={5} md={5}>
                            <InputLabel htmlFor={"game_update_away_team_id"}>{this.getTeamName(this.props.gameForm.away_team_id)}</InputLabel>
                            <div>
                                <TextField
                                    id="update-score-away"
                                    label="Away Score"
                                    className="modal-input score_input"
                                    fullWidth={true}
                                    value={this.props.gameForm.away_score}
                                    onChange={(e) => { this.props.dispatch(gameFormUpdate({ prop: 'away_score', value: e.target.value })); }}
                                    margin="normal"
                                />
                                <div className="score_forfeit_window">
                                    <InputLabel htmlFor={"is_away_forfeit"}>Forfeit?</InputLabel>
                                    <Checkbox
                                        checked={this.props.gameForm.is_away_forfeit}
                                        onChange={() => { this.props.dispatch(gameFormUpdate({ prop: 'is_away_forfeit', value: !this.props.gameForm.is_away_forfeit })); }}
                                        value="is_away_forfeit"
                                        color="primary"
                                    />
                                </div>
                            </div>
                        </GridItem>
                        <GridItem xs={12} sm={2} md={2}>
                                <p>VS.</p>
                        </GridItem>
                        <GridItem xs={12} sm={5} md={5}>
                            <InputLabel htmlFor={"game_update_away_team_id"}>{this.getTeamName(this.props.gameForm.home_team_id)}</InputLabel>
                            <div>
                                <TextField
                                    id="update-score-home"
                                    label="Home Score"
                                    className="modal-input score_input"
                                    fullWidth={true}
                                    value={this.props.gameForm.home_score}
                                    onChange={(e) => { this.props.dispatch(gameFormUpdate({ prop: 'home_score', value: e.target.value })); }}
                                    margin="normal"
                                />
                                <div className="score_forfeit_window">
                                    <InputLabel htmlFor={"is_home_forfeit"}>Forfeit?</InputLabel>
                                    <Checkbox
                                        checked={this.props.gameForm.is_home_forfeit}
                                        onChange={() => { this.props.dispatch(gameFormUpdate({ prop: 'is_home_forfeit', value: !this.props.gameForm.is_home_forfeit })); }}
                                        value="is_home_forfeit"
                                        color="primary"
                                    />
                                </div>
                            </div>
                            
                        </GridItem>
                    </GridContainer>

                    <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.submitUpdateScore}>
                        Update Score
                    </Button>
                </div>
            </Modal>
        )
    }

    renderGames() {
        const { classes } = this.props;
        const renderedDate = (this.state.selectedDate.getMonth()+1)+" "+this.state.selectedDate.getDate()+", "+this.state.selectedDate.getFullYear();
        const currentGames = getDayGames(this.props.games, this.state.selectedDate);

        if (this.props.teams.length > 0 && this.props.fields.length > 0) {
            return (
                <GridContainer className="container-full">
                    <GridItem xs={12} sm={12} md={4}>
                        <Calendar
                            calendarType="US"
                            className="schedule-calendar"
                            onClickDay={(theDay) => { this.setState((prevState) => ({
                                selectedDate: theDay,
                                gamesToAdd: [{
                                    away_team_id: 0,
                                    home_team_id: 0,
                                    field_id: 0,
                                    game_time: ''
                                }]
                            })) }}
                            tileClassName={({ date, view }) => {
                                let theClassName = "";
                                const today = new Date();

                                this.props.games.forEach((theGame) => {

                                    const theGameDate = new Date(theGame.game_time);

                                    // is it today?
                                    if (date.getMonth() == today.getMonth() && date.getFullYear() == today.getFullYear() && date.getDate() == today.getDate()) {
                                        theClassName = "cal-today"
                                    }

                                    // is it an active game day?
                                    if (date.getMonth() == theGameDate.getMonth() && date.getFullYear() == theGameDate.getFullYear() && date.getDate() == theGameDate.getDate()) {
                                        
                                        // if it's present or past...
                                        if (today >= date) {

                                            // if there are scores that still need to be entered...
                                            if (!theGame.is_played) {
                                                theClassName = "cal-has-active-games-needs-scores";
                                            }
                                            else if (theClassName != "cal-has-active-games-needs-scores") {
                                                theClassName = "cal-has-active-games";
                                            }
                                        }
                                        else {

                                            // it's the future. Set that it's a future game
                                            theClassName = "cal-has-active-games-future";
                                        }
                                    
                                    }

                                
                                });

                                return theClassName;
                            }}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={8}>
                        <Card>
                            <CardHeader color="primary" className="card-header">
                                <h4 className={classes.cardTitleWhite}>{getPrettyTime(this.state.selectedDate, true)}</h4>
                                <p className={classes.cardCategoryWhite}>
                                Schedule
                                </p>
                            </CardHeader>
                            <CardBody>
                                {(currentGames.length > 0) && (
                                    <Table
                                        tableHeaderColor="primary"
                                        tableHead={["Away", "Home", "Field", "Time", "Actions"]}
                                        tableData={currentGames.map((theGame) => [
                                            this.getTeamName(theGame.away_team_id), 
                                            this.getTeamName(theGame.home_team_id), 
                                            this.getFieldName(theGame.field_id), 
                                            getFormattedGameTime(theGame.game_time), 
                                            (
                                                <div>
                                                    <Icon className="fa fa-pen manager-table-icon manager-table-edit" onClick={() => this.toggleUpdateModal(true, theGame)} />
                                                    <Icon className="fa fa-baseball-ball manager-table-icon manager-table-add" onClick={() => this.toggleUpdateScoreModal(true, theGame)} />
                                                    <Icon className="fa fa-trash manager-table-icon manager-table-remove" onClick={() => this.removeGame(theGame)} />
                                                </div>
                                            )
                                        ])}
                                    />
                                )}
                                {(currentGames.length <= 0) && (
                                    <div>
                                        <h3>No Games Scheduled</h3>
                                        <p>Add a game by clicking on the button below:</p>
                                    </div>
                                )}
                                <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={() => this.toggleCreateModal(true)}>
                                    Add Games
                                </Button>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            );
        }
        else {
            return (
                <GridContainer className="container-full">
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="primary">
                                <p className={classes.cardCategoryWhite}>
                                    You Need to Add More Info!
                                </p>
                            </CardHeader>
                            <CardBody>
                                
                                <p>You need to make sure that you have teams and fields entered before adding games. You can take care of this below:</p>

                                <Button variant="contained" fullWidth={true} color="primary" className="button-bottom-stacked" onClick={() => { this.props.history.push('/'+this.props.league_id+'/teams'); }}>
                                    Add Teams
                                </Button>

                                <Button variant="contained" fullWidth={true} color="primary" onClick={() => { this.props.history.push('/'+this.props.league_id+'/fields'); }}>
                                    Add Fields
                                </Button>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            );
        }
    }
    
    render() {
        console.log("The Games");
        console.log(this.props.games);
        return (
            <div>
                { this.renderGames() }

                {this.renderCreateModal()}
                {this.renderUpdateModal()}
                {this.renderUpdateScoreModal()}
            </div>
        );
    }
};

const mapStateToProps = (state, props) => {

    return {
        isAuthenticated: state.auth.isAuthenticated,
        league_id: state.selectedLeague.id,
        loading: state.members.loading,
        games: state.playoffs.games,
        gameForm: state.gameForm,
        teams: state.teams.teams,
        fields: state.fields.fields,
        createModalOpen: state.modals.gamesCreate,
        updateModalOpen: state.modals.gamesUpdate,
        updateScoreModalOpen: state.modals.scoresUpdate,
    };
};

export default connect(mapStateToProps)(withStyles(styles)(PlayoffScheduleIndexPage));
