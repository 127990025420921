const teamFormDefaultState = {
    name: '',
    division_id: 0,
    teams: '',
    message: '',
    error: '',
};

export default (state = teamFormDefaultState, action) => {
    
    switch (action.type) {

        case 'TEAM_FORM_UPDATE':
            return {
                ...state,
                [action.field.prop]: action.field.value
            };

        case 'TEAM_FORM_ERROR':
            return {
                ...state,
                message: '',
                error: action.message
            }

        case 'TEAM_FORM_SUCCESS':
            return {
                ...state,
                error: '',
                message: action.message
            }

        case 'TEAM_FORM_RESET':
            return teamFormDefaultState;

        default:
            return state;
    }
};