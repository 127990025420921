import axios from 'axios';
import { API_URL } from '../config/constants';
import removeAuthToken from '../utils/removeAuthToken';
import { playerFormReset, playerFormError, playerFormSuccess } from './playerForm';
import { loadTeams } from './teams';
import { closePlayersCreate } from './modals';
import { setSelectedLeague } from './selectedLeague';

export const loadPlayers = (players) => ({
    type: 'LOAD_PLAYERS',
    players
});

export const setPlayersLoading = () => ({
    type: 'SET_PLAYERS_LOADING'
});

export const getTeamPlayers = (id, tid) => {

    return (dispatch) => {

        dispatch(setPlayersLoading());
        return axios.get(API_URL+'/api/players/'+id+'/'+tid)
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(loadPlayers(res.data.players));
                    dispatch(loadTeams(res.data.teams));
                    dispatch(setSelectedLeague(res.data.league));
                }
                else {
                    console.log(res.data);
                    console.log("Could not get players!");
                }
            })
            .catch(function(err) {
                
                // token is likely invalid; remove it and prompt the user to login again
                console.log(err);
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};


export const addPlayers = (id, tid, { players } = {}) => {
    
    return (dispatch) => {

        dispatch(setPlayersLoading());
        return axios.post(API_URL+'/api/players/'+id+'/'+tid+'/create', { players })
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(loadPlayers(res.data.players));
                    dispatch(playerFormReset());
                    dispatch(closePlayersCreate());
                }
                else {
                    dispatch(playerFormError(res.data.message));
                }
            })
            .catch(function(err) {
                
                // token is likely invalid; remove it and prompt the user to login again
                console.log(err);
                dispatch(playerFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const updatePlayer = (id, tid, pid, { name, position } = {}) => {
    
    return (dispatch) => {

        dispatch(setPlayersLoading());
        return axios.put(API_URL+'/api/players/'+id+'/'+tid+'/'+pid, { name, position })
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(loadPlayers(res.data.players));
                    dispatch(playerFormSuccess('player Updated!'));
                }
                else {
                    dispatch(playerFormError(res.data.message));
                    console.log(res.data);
                    console.log("Could not add your player!");
                }
            })
            .catch(function(err) {

                // token is likely invalid; remove it and prompt the user to login again
                dispatch(playerFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const removePlayer = (pid, id, tid) => {

    return (dispatch) => {

        dispatch(setPlayersLoading());
        return axios.delete(API_URL+'/api/players/'+id+'/'+tid+'/'+pid, {})
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(loadPlayers(res.data.players));
                }
                else {
                    // dispatch(playerFormError(res.data.message));
                    console.log(res.data);
                    console.log("Could not remove your player!");
                }
            })
            .catch(function(err) {

                // token is likely invalid; remove it and prompt the user to login again
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
}