import React from 'react';
import Favicon from 'react-favicon';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import DashboardPage from '../components/DashboardPage';
import UsersIndexPage from '../components/UsersIndexPage';
import RulesIndexPage from '../components/RulesIndexPage';
import FieldsIndexPage from '../components/FieldsIndexPage';
import TeamsIndexPage from '../components/TeamsIndexPage';
import TeamPlayersPage from '../components/TeamPlayersPage';
import ScheduleIndexPage from '../components/ScheduleIndexPage';
import ScoresIndexPage from '../components/ScoresIndexPage';
import PlayoffsIndexPage from '../components/PlayoffsIndexPage';
import PlayoffScheduleIndexPage from '../components/PlayoffScheduleIndexPage';
import PlayoffScoresIndexPage from '../components/PlayoffScoresIndexPage';
import NotFoundPage from '../components/NotFoundPage';
import LoginPage from '../components/LoginPage';
import Header from '../components/common/Header';
import LeftSidebar from '../components/common/LeftSidebar';

const MainRouter = (props) => {
    return (
        <BrowserRouter>
            <div>
                <Favicon url="/favicon.ico" />
                <Header />
                <LeftSidebar />
                <Switch>
                    <Route path="/" component={LoginPage} exact={true} />
                    <Route path="/login" component={LoginPage} exact={true} />
                    <Route path="/:id/dash/" component={DashboardPage} exact={true} />
                    <Route path="/:id/members/" component={UsersIndexPage} exact={true} />
                    <Route path="/:id/rules/" component={RulesIndexPage} exact={true} />
                    <Route path="/:id/fields/" component={FieldsIndexPage} exact={true} />
                    <Route path="/:id/teams/" component={TeamsIndexPage} exact={true} />
                    <Route path="/:id/players/:tid/" component={TeamPlayersPage} exact={true} />
                    <Route path="/:id/schedule/" component={ScheduleIndexPage} exact={true} />
                    <Route path="/:id/scores/" component={ScoresIndexPage} exact={true} />
                    <Route path="/:id/playoffs/" component={PlayoffsIndexPage} exact={true} />
                    <Route path="/:id/playoffs/schedule/" component={PlayoffScheduleIndexPage} exact={true} />
                    <Route path="/:id/playoffs/scores/" component={PlayoffScoresIndexPage} exact={true} />
                    <Route component={NotFoundPage} />
                </Switch>
            </div>
        </BrowserRouter>
    );
}

export default MainRouter;