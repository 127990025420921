import axios from 'axios';
import { API_URL } from '../config/constants';
import removeAuthToken from '../utils/removeAuthToken';
import { gameFormReset, gameFormError, gameFormSuccess } from './gameForm';
import { closeGamesCreate } from './modals';
import { getGameTime, getFormattedTime } from '../utils/dateUtil';
import { setSelectedLeague } from './selectedLeague';

export const loadGames = (games) => ({
    type: 'LOAD_GAMES',
    games
});

export const setGamesLoading = () => ({
    type: 'SET_GAMES_LOADING'
});

export const getLeagueGames = (id) => {

    return (dispatch) => {

        dispatch(setGamesLoading());
        return axios.get(API_URL+'/api/schedule/'+id)
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(loadGames(res.data.games));
                    dispatch(setSelectedLeague(res.data.league));
                }
                else {
                    console.log(res.data);
                    console.log("Could not get games!");
                }
            })
            .catch(function(err) {
                
                // token is likely invalid; remove it and prompt the user to login again
                console.log(err);
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};


export const addGames = (id, game_date, { games } = {}) => {
    
    return (dispatch) => {

        const paramGames = games.map((theGame) => {
            return {
                ...theGame,
                game_time: getGameTime(game_date, theGame.game_time)
            };
        });

        dispatch(setGamesLoading());
        return axios.post(API_URL+'/api/schedule/'+id+'/create', { games: paramGames })
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(loadGames(res.data.games));
                    dispatch(gameFormReset());
                    dispatch(closeGamesCreate());
                }
                else {
                    dispatch(gameFormError(res.data.message));
                }
            })
            .catch(function(err) {
                
                // token is likely invalid; remove it and prompt the user to login again
                console.log(err);
                dispatch(gameFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const updateGame = (id, gid, { away_team_id, home_team_id, field_id, game_time } = {}) => {
    
    return (dispatch) => {

        const theGameTime = getFormattedTime(game_time);

        dispatch(setGamesLoading());
        return axios.put(API_URL+'/api/schedule/'+id+'/'+gid, { away_team_id, home_team_id, field_id, game_time: theGameTime })
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(loadGames(res.data.games));
                    dispatch(gameFormSuccess('game Updated!'));
                }
                else {
                    dispatch(gameFormError(res.data.message));
                    console.log(res.data);
                    console.log("Could not add your game!");
                }
            })
            .catch(function(err) {

                // token is likely invalid; remove it and prompt the user to login again
                dispatch(gameFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const updateGameScore = (id, gid, { away_score, home_score, is_away_forfeit, is_home_forfeit } = {}) => {
    
    return (dispatch) => {

        dispatch(setGamesLoading());
        return axios.put(API_URL+'/api/scores/'+id+'/'+gid, { away_score, home_score, is_away_forfeit, is_home_forfeit })
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(loadGames(res.data.games));
                    dispatch(gameFormSuccess('Score Updated!'));
                }
                else {
                    dispatch(gameFormError(res.data.message));
                    console.log(res.data);
                    console.log("Could not update the game score.");
                }
            })
            .catch(function(err) {

                // token is likely invalid; remove it and prompt the user to login again
                dispatch(gameFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const removeGame = (gid, id) => {

    return (dispatch) => {

        dispatch(setGamesLoading());
        return axios.delete(API_URL+'/api/schedule/'+id+'/'+gid, {})
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(loadGames(res.data.games));
                }
                else {
                    // dispatch(gameFormError(res.data.message));
                    console.log(res.data);
                    console.log("Could not remove your game!");
                }
            })
            .catch(function(err) {

                // token is likely invalid; remove it and prompt the user to login again
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
}