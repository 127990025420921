const gameFormDefaultState = {
    home_team_id: 0,
    away_team_id: 0,
    field_id: 0,
    home_score: 0,
    away_score: 0,
    is_played: 0,
    is_away_forfeit: false,
    is_home_forfeit: false,
    game_date: '',
    game_time: new Date(),
    games: {},
    error: '',
    loading: false,
    success: ''
};

export default (state = gameFormDefaultState, action) => {
    
    switch (action.type) {

        case 'GAME_FORM_UPDATE':
            return {
                ...state,
                [action.field.prop]: action.field.value
            };

        case 'GAME_FORM_ERROR':
            return {
                ...state,
                message: '',
                error: action.message
            }

        case 'GAME_FORM_SUCCESS':
            return {
                ...state,
                error: '',
                message: action.message
            }

        case 'GAME_FORM_LOAD_DATE':
            return {
                ...state,
                game_date: action.game_date,
                game_time: action.game_time
            }

        case 'GAME_FORM_RESET':
            return gameFormDefaultState;

        default:
            return state;
    }
};