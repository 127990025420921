// the default list of rules, which is empty
const rulesDefaultState = {
    rules: [],
    loading: true
};

export default (state = rulesDefaultState, action) => {
    switch (action.type) {

        case 'SET_RULES_LOADING':
            return {
                ...state,
                loading: true
            };

        case 'SET_RULES':
            return {
                ...state,
                rules: action.rules,
                loading: false
            };

        default:
            return state;
    }
};