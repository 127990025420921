import React from "react";
import { connect } from 'react-redux';
import Calendar from 'react-calendar';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from '@material-ui/core/Icon';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';

import GridItem from "./common/GridItem";
import GridContainer from "./common/GridContainer";
import Table from "./common/Table";
import Card from "./common/Card";
import CardHeader from "./common/CardHeader";
import CardBody from "./common/CardBody";
import verifyLogin from '../utils/verifyLogin';
import { getFormattedGameTime, getPrettyTime } from '../utils/dateUtil';
import { getPlayoffGames, updatePlayoffGameScore } from '../actions/playoffs';
import { getLeagueTeams } from '../actions/teams';
import { getLeagueFields } from '../actions/fields';
import { gameFormUpdate, gameFormLoadDate, gameFormError, gameFormReset } from '../actions/gameForm';
import { openGamesCreate, closeGamesCreate, openGamesUpdate, closeGamesUpdate, openScoresUpdate, closeScoresUpdate } from '../actions/modals';
import { setSelectedLeagueID } from '../actions/selectedLeague';
import outstandingScoreGames from '../selectors/outstandingScoreGames';

// import setAuthToken from '../utils/setAuthToken';
const format = 'h:mm a';

const styles = {
    cardCategoryWhite: {
      "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
      },
      "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
      }
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
      }
    }
};


class PlayoffScoresIndexPage extends React.Component {

    constructor(props) {
        super(props);

        this.submitUpdateScore = this.submitUpdateScore.bind(this);

        this.state = {
            selectedDate: new Date(),
            selectedGame: {},
            filterTeam: '',
            gamesToAdd: [{
                away_team_id: 0,
                home_team_id: 0,
                field_id: 0,
                game_time: ''
            }]
        };
    }

    componentDidMount() {
        this.props.dispatch(setSelectedLeagueID(this.props.match.params.id));
        verifyLogin(this.props.history);

        this.props.dispatch(getPlayoffGames(this.props.match.params.id));

        // get the teams (if we need to)
        if (this.props.teams <= 0) {
            this.props.dispatch(getLeagueTeams(this.props.match.params.id));
        }

        // get the fields (if we need to)
        if (this.props.fields <= 0) {
            this.props.dispatch(getLeagueFields(this.props.match.params.id));
        }
    }

    componentDidUpdate() {
        verifyLogin(this.props.history);
    }

    getTeamName(team_id) {
        let teamName = '';

        this.props.teams.forEach((theTeam) => {
            if (theTeam.id == team_id) {
                teamName = theTeam.name;
            }
        });

        return teamName;
    }

    getFieldName(field_id) {
        let fieldName = '';

        this.props.fields.forEach((theField) => {
            if (theField.id == field_id) {
                fieldName = theField.name;
            }
        });

        return fieldName;
    }

    toggleUpdateScoreModal(isOpen, selectedGame) {
        console.log("selectedGame");
        console.log(selectedGame);
        if (isOpen) {
            this.setState(() => ({
                selectedGame
            }));
            this.props.dispatch(gameFormReset());
            this.props.dispatch(gameFormUpdate({ prop: 'away_score', value: selectedGame.away_score }));
            this.props.dispatch(gameFormUpdate({ prop: 'home_score', value: selectedGame.home_score }));
            this.props.dispatch(gameFormUpdate({ prop: 'away_team_id', value: selectedGame.away_team_id }));
            this.props.dispatch(gameFormUpdate({ prop: 'home_team_id', value: selectedGame.home_team_id }));
            this.props.dispatch(gameFormUpdate({ prop: 'is_away_forfeit', value: selectedGame.is_away_forfeit }));
            this.props.dispatch(gameFormUpdate({ prop: 'is_home_forfeit', value: selectedGame.is_home_forfeit }));
            this.props.dispatch(openScoresUpdate());
        }
        else {
            this.props.dispatch(closeScoresUpdate());
        }
    }

    submitUpdateScore() {

        // update the schedule game
        this.props.dispatch(updatePlayoffGameScore(this.props.match.params.id, this.state.selectedGame.id, this.props.gameForm));
    }

    renderUpdateScoreModal() {
        return (
            <Modal
                aria-labelledby="score-update"
                aria-describedby="score-update"
                open={this.props.updateScoreModalOpen}
                onClose={() => this.toggleUpdateScoreModal(false)}
                className="modal_admin"
            >
                <div className="modal-member">
                    <Typography variant="h4" className="modal-title">
                        Update Game Score
                    </Typography>
                    <Typography className="modal-description">
                        Please update the home and away score, and click 'Update' to update the game score!
                    </Typography>

                    { this.props.gameForm.error && (
                        <Typography className="modal-error">
                            { this.props.gameForm.error }
                        </Typography>
                    )}

                    { this.props.gameForm.message && (
                        <Typography className="modal-success">
                            { this.props.gameForm.message }
                        </Typography>
                    )}

                    <Typography variant="h5" className="modal-title">
                        Teams
                    </Typography>
                    <GridContainer>
                        <GridItem xs={12} sm={5} md={5}>
                            <InputLabel htmlFor={"game_update_away_team_id"}>{this.getTeamName(this.props.gameForm.away_team_id)}</InputLabel>
                            <div>
                                <TextField
                                    id="update-score-away"
                                    label="Away Score"
                                    className="modal-input score_input"
                                    fullWidth={true}
                                    value={this.props.gameForm.away_score}
                                    onChange={(e) => { this.props.dispatch(gameFormUpdate({ prop: 'away_score', value: e.target.value })); }}
                                    margin="normal"
                                />
                                <div className="score_forfeit_window">
                                    <InputLabel htmlFor={"is_away_forfeit"}>Forfeit?</InputLabel>
                                    <Checkbox
                                        checked={this.props.gameForm.is_away_forfeit}
                                        onChange={() => { this.props.dispatch(gameFormUpdate({ prop: 'is_away_forfeit', value: !this.props.gameForm.is_away_forfeit })); }}
                                        value="is_away_forfeit"
                                        color="primary"
                                    />
                                </div>
                            </div>
                        </GridItem>
                        <GridItem xs={12} sm={2} md={2}>
                                <p>VS.</p>
                        </GridItem>
                        <GridItem xs={12} sm={5} md={5}>
                            <InputLabel htmlFor={"game_update_away_team_id"}>{this.getTeamName(this.props.gameForm.home_team_id)}</InputLabel>
                            <div>
                                <TextField
                                    id="update-score-home"
                                    label="Home Score"
                                    className="modal-input score_input"
                                    fullWidth={true}
                                    value={this.props.gameForm.home_score}
                                    onChange={(e) => { this.props.dispatch(gameFormUpdate({ prop: 'home_score', value: e.target.value })); }}
                                    margin="normal"
                                />
                                <div className="score_forfeit_window">
                                    <InputLabel htmlFor={"is_home_forfeit"}>Forfeit?</InputLabel>
                                    <Checkbox
                                        checked={this.props.gameForm.is_home_forfeit}
                                        onChange={() => { this.props.dispatch(gameFormUpdate({ prop: 'is_home_forfeit', value: !this.props.gameForm.is_home_forfeit })); }}
                                        value="is_home_forfeit"
                                        color="primary"
                                    />
                                </div>
                            </div>
                            
                        </GridItem>
                    </GridContainer>

                    <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.submitUpdateScore}>
                        Update Score
                    </Button>
                </div>
            </Modal>
        )
    }

    renderGames() {
        const { classes } = this.props;
        
        // @TODO: update this!
        const currentGames = outstandingScoreGames(this.props.games, this.state.selectedDate, this.state.filterTeam);

        if (this.props.teams.length > 0 && this.props.fields.length > 0) {
            return (
                <GridContainer className="container-full">
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="primary" className="card-header">
                                <p className={classes.cardCategoryWhite}>
                                    Outstanding Scores
                                </p>
                            </CardHeader>
                            <CardBody>
                                {(currentGames.length > 0) && (
                                    <div className="filter-input">
                                        <InputLabel htmlFor="filterTeam">Filter By Team:</InputLabel>
                                        <Select
                                            value={this.state.filterTeam}
                                            onChange={(e) => { this.setState(() => ({ filterTeam: e.target.value })); }}
                                            fullWidth={true}
                                            inputProps={{
                                                name: 'filterTeam',
                                                id: 'filterTeam',
                                            }}
                                        >
                                            <MenuItem key={''} value={''}>All Games</MenuItem>
                                            {this.props.teams.map((team) => <MenuItem key={team.id} value={team.id}>{team.name}</MenuItem>)}
                                        </Select>
                                        <Table
                                            tableHeaderColor="primary"
                                            tableHead={["Away", "Home", "Field", "Date", "Time", "Actions"]}
                                            tableData={currentGames.map((theGame) => [
                                                this.getTeamName(theGame.away_team_id), 
                                                this.getTeamName(theGame.home_team_id), 
                                                this.getFieldName(theGame.field_id), 
                                                getPrettyTime(theGame.game_time, true),
                                                getFormattedGameTime(theGame.game_time), 
                                                (
                                                    <Icon className="fa fa-baseball-ball manager-table-icon manager-table-add" onClick={() => this.toggleUpdateScoreModal(true, theGame)} />
                                                )
                                            ])}
                                        />
                                    </div>
                                )}
                                {(currentGames.length <= 0) && (
                                    <div>
                                        <h3>No Outstanding Games!</h3>
                                        <p>Looks like all of the scores are up to date!</p>
                                    </div>
                                )}
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            );
        }
        else {
            return (
                <GridContainer className="container-full">
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="primary">
                                <p className={classes.cardCategoryWhite}>
                                    You Need to Add More Info!
                                </p>
                            </CardHeader>
                            <CardBody>
                                
                                <p>You need to make sure that you have teams and fields entered before adding games. You can take care of this below:</p>

                                <Button variant="contained" fullWidth={true} color="primary" className="button-bottom-stacked" onClick={() => { this.props.history.push('/'+this.props.league_id+'/teams'); }}>
                                    Add Teams
                                </Button>

                                <Button variant="contained" fullWidth={true} color="primary" onClick={() => { this.props.history.push('/'+this.props.league_id+'/fields'); }}>
                                    Add Fields
                                </Button>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            );
        }
    }
    
    render() {
        return (
            <div>
                { this.renderGames() }
                {this.renderUpdateScoreModal()}
            </div>
        );
    }
};

const mapStateToProps = (state, props) => {

    return {
        isAuthenticated: state.auth.isAuthenticated,
        league_id: state.selectedLeague.id,
        loading: state.members.loading,
        games: state.playoffs.games,
        gameForm: state.gameForm,
        teams: state.teams.teams,
        fields: state.fields.fields,
        createModalOpen: state.modals.gamesCreate,
        updateModalOpen: state.modals.gamesUpdate,
        updateScoreModalOpen: state.modals.scoresUpdate,
    };
};

export default connect(mapStateToProps)(withStyles(styles)(PlayoffScoresIndexPage));
