import React from 'react';
import { connect } from 'react-redux';
import verifyLogin from '../utils/verifyLogin';
import setBackgroundPhoto from '../utils/setBackgroundPhoto';
import { setSelectedLeagueID } from '../actions/selectedLeague';

class DashboardPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            sidebarMenuOpen: false
        };
    }

    componentDidMount() {
        this.props.dispatch(setSelectedLeagueID(this.props.match.params.id));
        verifyLogin(this.props.history);

    }

    componentDidUpdate() {
        verifyLogin(this.props.history);
    }
    
    render() {
        setBackgroundPhoto('');

        return (
            <div>
            </div>
        )
    }
};

const mapStateToProps = (state, props) => {
    return {
        isAuthenticated: state.auth.isAuthenticated
    };
};

export default connect(mapStateToProps)(DashboardPage);