// the default list of members, which is empty
const membersDefaultState = {
    members: [],
    loading: true
};

export default (state = membersDefaultState, action) => {
    switch (action.type) {

        case 'SET_MEMBERS_LOADING':
            return {
                ...state,
                loading: true
            };

        case 'SET_MEMBERS':
            return {
                ...state,
                members: action.members,
                loading: false
            };

        default:
            return state;
    }
};