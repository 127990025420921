import React from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from '@material-ui/core/Icon';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

import GridItem from "./common/GridItem";
import GridContainer from "./common/GridContainer";
import Table from "./common/Table";
import Card from "./common/Card";
import CardHeader from "./common/CardHeader";
import CardBody from "./common/CardBody";
import verifyLogin from '../utils/verifyLogin';
import { roundFormUpdate, roundFormError, roundFormReset } from '../actions/roundForm';
import { 
    getPlayoffRounds, 
    addRound, 
    updateRound, 
    removeRound, 
    addPlayoffDivision, 
    updatePlayoffDivision, 
    removePlayoffDivision,
    addPlayoffMatchup, 
    updatePlayoffMatchup, 
    removePlayoffMatchup,
    addPlayoffDivisionTeam,
    removePlayoffDivisionTeam,
    loadSelectedDivisionTeams } from '../actions/playoffs';
import { divisionFormUpdate, divisionFormError, divisionFormReset } from '../actions/divisionForm';
import { matchupFormUpdate, matchupFormError, matchupFormReset } from '../actions/matchupForm';
import { 
    openRoundsCreate, 
    closeRoundsCreate, 
    openRoundsUpdate, 
    closeRoundsUpdate,
    openRoundDivisionsCreate, 
    closeRoundDivisionsCreate, 
    openRoundDivisionsUpdate, 
    closeRoundDivisionsUpdate,
    openRoundDivisionTeams, 
    closeRoundDivisionTeams,
    openRoundMatchupsCreate, 
    closeRoundMatchupsCreate, 
    openRoundMatchupsUpdate, 
    closeRoundMatchupsUpdate } from '../actions/modals';
import { setSelectedLeagueID } from '../actions/selectedLeague';


// import setAuthToken from '../utils/setAuthToken';

const styles = {
    cardCategoryWhite: {
      "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
      },
      "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
      }
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
      }
    }
};

class PlayoffsIndexPage extends React.Component {

    constructor(props) {
        super(props);

        this.toggleRoundCreateModal = this.toggleRoundCreateModal.bind(this);
        this.toggleRoundUpdateModal = this.toggleRoundUpdateModal.bind(this);
        this.submitCreateRound = this.submitCreateRound.bind(this);
        this.submitUpdateRound = this.submitUpdateRound.bind(this);
        this.toggleRoundDivisionCreateModal = this.toggleRoundDivisionCreateModal.bind(this);
        this.toggleRoundDivisionUpdateModal = this.toggleRoundDivisionUpdateModal.bind(this);
        this.toggleRoundDivisionTeamsModal = this.toggleRoundDivisionTeamsModal.bind(this);
        this.toggleRoundMatchupCreateModal = this.toggleRoundMatchupCreateModal.bind(this);
        this.toggleRoundMatchupUpdateModal = this.toggleRoundMatchupUpdateModal.bind(this);
        this.submitCreatePlayoffDivision = this.submitCreatePlayoffDivision.bind(this);
        this.submitUpdatePlayoffDivision = this.submitUpdatePlayoffDivision.bind(this);
        this.submitCreatePlayoffMatchup = this.submitCreatePlayoffMatchup.bind(this);
        this.submitUpdatePlayoffMatchup = this.submitUpdatePlayoffMatchup.bind(this);
        this.submitAddPlayoffDivisionTeam = this.submitAddPlayoffDivisionTeam.bind(this);

        this.state = {
            selectedRound: {},
            selectedDivision: { teams: [] },
            selectedMatchup: {},
            selectedDivisionTeam: 0
        };
    }

    componentDidMount() {
        this.props.dispatch(setSelectedLeagueID(this.props.match.params.id));
        verifyLogin(this.props.history);

        this.props.dispatch(getPlayoffRounds(this.props.match.params.id));
    }

    componentDidUpdate() {
        verifyLogin(this.props.history);
    }

    toggleRoundCreateModal(isOpen) {
        if (isOpen) {
            this.props.dispatch(roundFormReset());
            this.props.dispatch(openRoundsCreate());
        }
        else {
            this.props.dispatch(closeRoundsCreate());
        }
    }

    toggleRoundUpdateModal(isOpen, selectedRound) {
        if (isOpen) {
            this.setState(() => ({
                selectedRound
            }));
            this.props.dispatch(roundFormReset());
            this.props.dispatch(roundFormUpdate({ prop: 'name', value: selectedRound.name }));
            this.props.dispatch(roundFormUpdate({ prop: 'type', value: selectedRound.type }));
            this.props.dispatch(roundFormUpdate({ prop: 'start_date', value: new Date(selectedRound.start_date) }));
            this.props.dispatch(roundFormUpdate({ prop: 'end_date', value: new Date(selectedRound.end_date) }));
            this.props.dispatch(openRoundsUpdate());
        }
        else {
            this.props.dispatch(closeRoundsUpdate());
        }
    }

    toggleRoundDivisionCreateModal(isOpen, setRound = 0) {
        if (isOpen) {
            this.props.dispatch(divisionFormReset());
            if (setRound != 0) {
                this.props.dispatch(divisionFormUpdate({ prop: 'round_id', value: setRound }));
            }
            this.props.dispatch(openRoundDivisionsCreate());
        }
        else {
            this.props.dispatch(closeRoundDivisionsCreate());
        }
    }

    toggleRoundDivisionUpdateModal(isOpen, selectedDivision) {
        if (isOpen) {
            this.setState(() => ({
                selectedDivision
            }));
            this.props.dispatch(divisionFormReset());
            this.props.dispatch(divisionFormUpdate({ prop: 'round_id', value: selectedDivision.round_id }));
            this.props.dispatch(divisionFormUpdate({ prop: 'name', value: selectedDivision.name }));
            this.props.dispatch(openRoundDivisionsUpdate());
        }
        else {
            this.props.dispatch(closeRoundDivisionsUpdate());
        }
    }

    toggleRoundDivisionTeamsModal(isOpen, selectedDivision) {
        if (isOpen) {
            this.setState(() => ({
                selectedDivision
            }));

            this.props.dispatch(loadSelectedDivisionTeams(selectedDivision.teams));
            this.props.dispatch(openRoundDivisionTeams());
        }
        else {
            this.props.dispatch(closeRoundDivisionTeams());
        }
    }

    toggleRoundMatchupCreateModal(isOpen, setRound = 0) {
        if (isOpen) {
            this.props.dispatch(matchupFormReset());
            if (setRound != 0) {
                this.props.dispatch(matchupFormUpdate({ prop: 'round_id', value: setRound }));
            }
            this.props.dispatch(openRoundMatchupsCreate());
        }
        else {
            this.props.dispatch(closeRoundMatchupsCreate());
        }
    }

    toggleRoundMatchupUpdateModal(isOpen, selectedMatchup) {
        if (isOpen) {
            this.setState(() => ({
                selectedMatchup
            }));
            this.props.dispatch(matchupFormReset());
            this.props.dispatch(matchupFormUpdate({ prop: 'round_id', value: selectedMatchup.round_id }));
            this.props.dispatch(matchupFormUpdate({ prop: 'name', value: selectedMatchup.name }));
            this.props.dispatch(matchupFormUpdate({ prop: 'home_team_id', value: selectedMatchup.home_team_id }));
            this.props.dispatch(matchupFormUpdate({ prop: 'away_team_id', value: selectedMatchup.away_team_id }));
            this.props.dispatch(openRoundMatchupsUpdate());
        }
        else {
            this.props.dispatch(closeRoundMatchupsUpdate());
        }
    }

    submitCreateRound() {

        // perform some error checking
        if (this.props.roundForm.name.trim() == "") {

            // required fields aren't present
            this.props.dispatch(roundFormError("Please enter a round name."));
        }
        else {

            // add the round
            this.props.dispatch(addRound(this.props.match.params.id, this.props.roundForm));
        }
    }

    submitUpdateRound() {

        // perform some error checking
        if (this.props.roundForm.name.trim() == "") {

            // required fields aren't present
            this.props.dispatch(roundFormError("Please enter a round name."));
        }
        else if (this.props.roundForm.start_date == "") {

            // required fields aren't present
            this.props.dispatch(roundFormError("Please enter a round start date."));
        }
        else if (this.props.roundForm.end_date == "") {

            // required fields aren't present
            this.props.dispatch(roundFormError("Please enter a round end date."));
        }
        else {

            // update the playoff round
            this.props.dispatch(updateRound(this.props.match.params.id, this.state.selectedRound.id, this.props.roundForm));
        }
    }

    removeRound({ id }) {
        if (confirm("Are you sure you want to remove this playoff round? This cannot be undone.")) {
            this.props.dispatch(removeRound(id, this.props.match.params.id));
        };
    }

    submitCreatePlayoffDivision() {

        // perform some error checking
        if (this.props.divisionForm.name.trim() == "") {

            // required fields aren't present
            this.props.dispatch(divisionFormError("Please enter a division name."));
        }
        else {

            // add the division
            this.props.dispatch(addPlayoffDivision(this.props.match.params.id, this.props.divisionForm));
        }
    }

    submitUpdatePlayoffDivision() {

        // perform some error checking
        if (this.props.divisionForm.name.trim() == "") {

            // required fields aren't present
            this.props.dispatch(divisionFormError("Please enter a division name."));
        }
        else {

            // add the team
            this.props.dispatch(updatePlayoffDivision(this.props.match.params.id, this.state.selectedDivision.id, this.props.divisionForm));
        }
    }

    removePlayoffDivision({ id }) {
        if (confirm("Are you sure you want to remove this division? This cannot be undone.")) {
            this.props.dispatch(removePlayoffDivision(id, this.props.match.params.id));
        };
    }

    submitAddPlayoffDivisionTeam() {
        this.props.dispatch(addPlayoffDivisionTeam(this.props.match.params.id, this.state.selectedDivision.id, this.state.selectedDivisionTeam));
    }

    submitRemovePlayoffDivisionTeam({ id }, team_id) {
        if (confirm("Are you sure you want to remove this division team? This cannot be undone.")) {
            this.props.dispatch(removePlayoffDivisionTeam(this.props.match.params.id, id, team_id));
        };
    }

    submitCreatePlayoffMatchup() {

        // perform some error checking
        if (this.props.matchupForm.name.trim() == "") {

            // required fields aren't present
            this.props.dispatch(matchupFormError("Please enter a matchup name."));
        }
        else if (this.props.matchupForm.home_team_id == 0) {

            // required fields aren't present
            this.props.dispatch(matchupFormError("Please enter a home team."));
        }
        else if (this.props.matchupForm.away_team_id == 0) {

            // required fields aren't present
            this.props.dispatch(matchupFormError("Please enter an away team."));
        }
        else {

            // add the matchup
            this.props.dispatch(addPlayoffMatchup(this.props.match.params.id, this.props.matchupForm));
        }
    }

    submitUpdatePlayoffMatchup() {

        // perform some error checking
        if (this.props.matchupForm.name.trim() == "") {

            // required fields aren't present
            this.props.dispatch(matchupFormError("Please enter a matchup name."));
        }
        else if (this.props.matchupForm.home_team_id == 0) {

            // required fields aren't present
            this.props.dispatch(matchupFormError("Please enter a home team."));
        }
        else if (this.props.matchupForm.away_team_id == 0) {

            // required fields aren't present
            this.props.dispatch(matchupFormError("Please enter an away team."));
        }
        else {

            // add the team
            this.props.dispatch(updatePlayoffMatchup(this.props.match.params.id, this.state.selectedMatchup.id, this.props.matchupForm));
        }
    }

    removePlayoffMatchup({ id }) {
        if (confirm("Are you sure you want to remove this matchup? This cannot be undone.")) {
            this.props.dispatch(removePlayoffMatchup(id, this.props.match.params.id));
        };
    }

    getTeamName(team_id) {
        let teamName = '';

        this.props.teams.forEach((theTeam) => {
            if (theTeam.id == team_id) {
                teamName = theTeam.name;
            }
        });

        return teamName;
    }

    renderCreateRoundModal() {
        return (
            <Modal
                aria-labelledby="round-add"
                aria-describedby="round-add"
                open={this.props.createPlayoffRoundModalOpen}
                onClose={() => this.toggleRoundCreateModal(false)}
                className="modal_admin"
            >
                <div className="modal-member">
                    <Typography variant="h4" className="modal-title">
                        Add a Playoff Round
                    </Typography>
                    <Typography className="modal-description">
                        Please add the playoff round name, type, start and end date, then click 'Add Round'.
                    </Typography>

                    { this.props.roundForm.error && (
                        <Typography className="modal-error">
                            { this.props.roundForm.error }
                        </Typography>
                    )}

                    <TextField
                        id="create-round-name"
                        label="Name"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.roundForm.name}
                        onChange={(e) => { this.props.dispatch(roundFormUpdate({ prop: 'name', value: e.target.value })); }}
                        margin="normal"
                    />

                    <FormControl className="modal-select">
                        <InputLabel htmlFor="type">Type</InputLabel>
                        <Select
                            value={this.props.roundForm.type}
                            onChange={(e) => { this.props.dispatch(roundFormUpdate({ prop: 'type', value: e.target.value })); }}
                            fullWidth={true}
                            inputProps={{
                                name: 'type',
                                id: 'create-round-type',
                            }}
                        >
                            <MenuItem value={"round_robin"}>Round Robin</MenuItem>
                            <MenuItem value={"playoff_round"}>Elimination Round</MenuItem>
                        </Select>
                    </FormControl>

                    <GridContainer>
                        <GridItem xs={12} sm={6} md={6}>
                            <FormControl className="modal-select">
                                <InputLabel htmlFor="start_date">Start Date</InputLabel>
                                <DatePicker
                                    selected={this.props.roundForm.start_date}
                                    onChange={(value) => { this.props.dispatch(roundFormUpdate({ prop: 'start_date', value })); }}
                                    dateFormat="MMMM d, yyyy"
                                    className="modal-datepicker-playoffs"
                                />
                            </FormControl>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6}>
                            <FormControl className="modal-select">
                                <InputLabel htmlFor="end_date">End Date</InputLabel>
                                <DatePicker
                                    selected={this.props.roundForm.end_date}
                                    onChange={(value) => { this.props.dispatch(roundFormUpdate({ prop: 'end_date', value })); }}
                                    dateFormat="MMMM d, yyyy"
                                    className="modal-datepicker-playoffs"
                                />
                            </FormControl>
                        </GridItem>
                    </GridContainer>

                    <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.submitCreateRound}>
                        Add Round
                    </Button>
                </div>
            </Modal>
        )
    }

    renderUpdateRoundModal() {
        return (
            <Modal
                aria-labelledby="round-update"
                aria-describedby="round-update"
                open={this.props.updatePlayoffRoundModalOpen}
                onClose={() => this.toggleRoundUpdateModal(false)}
                className="modal_admin"
            >
                <div className="modal-member">
                    <Typography variant="h4" className="modal-title">
                        Update Round
                    </Typography>
                    <Typography className="modal-description">
                        You can update the round name if you like, then click 'Update Round'.
                    </Typography>

                    { this.props.roundForm.error && (
                        <Typography className="modal-error">
                            { this.props.roundForm.error }
                        </Typography>
                    )}

                    { this.props.roundForm.message && (
                        <Typography className="modal-success">
                            { this.props.roundForm.message }
                        </Typography>
                    )}

                    <TextField
                        id="update-round-name"
                        label="Name"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.roundForm.name}
                        onChange={(e) => { this.props.dispatch(roundFormUpdate({ prop: 'name', value: e.target.value })); }}
                        margin="normal"
                    />

                    <GridContainer>
                        <GridItem xs={12} sm={6} md={6}>
                            <FormControl className="modal-select">
                                <InputLabel htmlFor="start_date">Start Date</InputLabel>
                                <DatePicker
                                    selected={this.props.roundForm.start_date}
                                    onChange={(value) => { this.props.dispatch(roundFormUpdate({ prop: 'start_date', value })); }}
                                    dateFormat="MMMM d, yyyy"
                                    className="modal-datepicker-playoffs"
                                />
                            </FormControl>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6}>
                            <FormControl className="modal-select">
                                <InputLabel htmlFor="end_date">End Date</InputLabel>
                                <DatePicker
                                    selected={this.props.roundForm.end_date}
                                    onChange={(value) => { this.props.dispatch(roundFormUpdate({ prop: 'end_date', value })); }}
                                    dateFormat="MMMM d, yyyy"
                                    className="modal-datepicker-playoffs"
                                />
                            </FormControl>
                        </GridItem>
                    </GridContainer>

                    <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.submitUpdateRound}>
                        Update Round
                    </Button>
                </div>
            </Modal>
        )
    }

    renderCreatePlayoffDivisionModal() {

        return (
            <Modal
                aria-labelledby="division-add"
                aria-describedby="division-add"
                open={this.props.createPlayoffDivisionModalOpen}
                onClose={() => this.toggleRoundDivisionCreateModal(false)}
                className="modal_admin"
            >
                <div className="modal-member">
                    <Typography variant="h4" className="modal-title">
                        Add a Playoff Division
                    </Typography>
                    <Typography className="modal-description">
                        Please add the division name, then click 'Add Division'.
                    </Typography>

                    { this.props.divisionForm.error && (
                        <Typography className="modal-error">
                            { this.props.divisionForm.error }
                        </Typography>
                    )}

                    <TextField
                        id="create-division-name"
                        label="Name"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.divisionForm.name}
                        onChange={(e) => { this.props.dispatch(divisionFormUpdate({ prop: 'name', value: e.target.value })); }}
                        margin="normal"
                    />

                    <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.submitCreatePlayoffDivision}>
                        Add Division
                    </Button>
                </div>
            </Modal>
        )
    }

    renderUpdatePlayoffDivisionModal() {
        return (
            <Modal
                aria-labelledby="division-update"
                aria-describedby="division-update"
                open={this.props.updatePlayoffDivisionModalOpen}
                onClose={() => this.toggleRoundDivisionUpdateModal(false)}
                className="modal_admin"
            >
                <div className="modal-member">
                    <Typography variant="h4" className="modal-title">
                        Update Playoff Division
                    </Typography>
                    <Typography className="modal-description">
                        You can update the division name if you like, then click 'Update Division'.
                    </Typography>

                    { this.props.divisionForm.error && (
                        <Typography className="modal-error">
                            { this.props.divisionForm.error }
                        </Typography>
                    )}

                    { this.props.divisionForm.message && (
                        <Typography className="modal-success">
                            { this.props.divisionForm.message }
                        </Typography>
                    )}

                    <TextField
                        id="update-division-name"
                        label="Name"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.divisionForm.name}
                        onChange={(e) => { this.props.dispatch(divisionFormUpdate({ prop: 'name', value: e.target.value })); }}
                        margin="normal"
                    />

                    <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.submitUpdatePlayoffDivision}>
                        Update Division
                    </Button>
                </div>
            </Modal>
        )
    }

    renderCreatePlayoffMatchupModal() {

        return (
            <Modal
                aria-labelledby="matchup-add"
                aria-describedby="matchup-add"
                open={this.props.createPlayoffMatchupModalOpen}
                onClose={() => this.toggleRoundMatchupCreateModal(false)}
                className="modal_admin"
            >
                <div className="modal-member">
                    <Typography variant="h4" className="modal-title">
                        Add a Playoff Matchup
                    </Typography>
                    <Typography className="modal-description">
                        Please add the matchup name and teams, then click 'Add Matchup'.
                    </Typography>

                    { this.props.matchupForm.error && (
                        <Typography className="modal-error">
                            { this.props.matchupForm.error }
                        </Typography>
                    )}

                    <TextField
                        id="create-matchup-name"
                        label="Name"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.matchupForm.name}
                        onChange={(e) => { this.props.dispatch(matchupFormUpdate({ prop: 'name', value: e.target.value })); }}
                        margin="normal"
                    />

                    <GridContainer>
                        <GridItem xs={12} sm={6} md={6}>
                            <InputLabel htmlFor={"away_team_id"}>Away Team</InputLabel>
                            <Select
                                value={this.props.matchupForm.away_team_id}
                                onChange={(e) => { this.props.dispatch(matchupFormUpdate({ prop: 'away_team_id', value: e.target.value })); }}
                                fullWidth={true}
                                inputProps={{
                                    name: "away_team_id",
                                }}
                            >
                                {this.props.teams.map((theTeam) => <MenuItem key={theTeam.id} value={theTeam.id}>{theTeam.name}</MenuItem>)}
                            </Select>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6}>
                            <InputLabel htmlFor={"home_team_id"}>Home Team</InputLabel>
                            <Select
                                value={this.props.matchupForm.home_team_id}
                                onChange={(e) => { this.props.dispatch(matchupFormUpdate({ prop: 'home_team_id', value: e.target.value })); }}
                                fullWidth={true}
                                inputProps={{
                                    name: "home_team_id"
                                }}
                            >
                                {this.props.teams.map((theTeam) => <MenuItem key={theTeam.id} value={theTeam.id}>{theTeam.name}</MenuItem>)}
                            </Select>
                        </GridItem>
                    </GridContainer>

                    <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.submitCreatePlayoffMatchup}>
                        Add Matchup
                    </Button>
                </div>
            </Modal>
        )
    }

    renderUpdatePlayoffMatchupModal() {
        return (
            <Modal
                aria-labelledby="matchup-update"
                aria-describedby="matchup-update"
                open={this.props.updatePlayoffMatchupModalOpen}
                onClose={() => this.toggleRoundMatchupUpdateModal(false)}
                className="modal_admin"
            >
                <div className="modal-member">
                    <Typography variant="h4" className="modal-title">
                        Update Playoff Matchup
                    </Typography>
                    <Typography className="modal-description">
                        You can update the matchup name and teams, then click 'Update Matchup'.
                    </Typography>

                    { this.props.matchupForm.error && (
                        <Typography className="modal-error">
                            { this.props.matchupForm.error }
                        </Typography>
                    )}

                    { this.props.matchupForm.message && (
                        <Typography className="modal-success">
                            { this.props.matchupForm.message }
                        </Typography>
                    )}

                    <TextField
                        id="update-matchup-name"
                        label="Name"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.matchupForm.name}
                        onChange={(e) => { this.props.dispatch(matchupFormUpdate({ prop: 'name', value: e.target.value })); }}
                        margin="normal"
                    />

                    <GridContainer>
                        <GridItem xs={12} sm={6} md={6}>
                            <InputLabel htmlFor={"away_team_id"}>Away Team</InputLabel>
                            <Select
                                value={this.props.matchupForm.away_team_id}
                                onChange={(e) => { this.props.dispatch(matchupFormUpdate({ prop: 'away_team_id', value: e.target.value })); }}
                                fullWidth={true}
                                inputProps={{
                                    name: "away_team_id",
                                }}
                            >
                                {this.props.teams.map((theTeam) => <MenuItem key={theTeam.id} value={theTeam.id}>{theTeam.name}</MenuItem>)}
                            </Select>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6}>
                            <InputLabel htmlFor={"home_team_id"}>Home Team</InputLabel>
                            <Select
                                value={this.props.matchupForm.home_team_id}
                                onChange={(e) => { this.props.dispatch(matchupFormUpdate({ prop: 'home_team_id', value: e.target.value })); }}
                                fullWidth={true}
                                inputProps={{
                                    name: "home_team_id",
                                }}
                            >
                                {this.props.teams.map((theTeam) => <MenuItem key={theTeam.id} value={theTeam.id}>{theTeam.name}</MenuItem>)}
                            </Select>
                        </GridItem>
                    </GridContainer>

                    <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.submitUpdatePlayoffMatchup}>
                        Update Matchup
                    </Button>
                </div>
            </Modal>
        )
    }

    renderPlayoffDivisionTeamsModal() {

        return (
            <Modal
                aria-labelledby="division-teams"
                aria-describedby="division-teams"
                open={this.props.updatePlayoffDivisionTeamsModalOpen}
                onClose={() => this.toggleRoundDivisionTeamsModal(false)}
                className="modal_admin"
            >
                <div className="modal-member">
                    <Typography variant="h4" className="modal-title">
                        Playoff Division Teams
                    </Typography>
                    <Typography className="modal-description">
                        You can add and remove teams from the division here.
                    </Typography>

                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <InputLabel htmlFor={"team_id"}>Team to Add</InputLabel>
                            <Select
                                value={this.state.selectedDivisionTeam}
                                onChange={(e) => { this.setState(() => ({ selectedDivisionTeam: e.target.value })) }}
                                fullWidth={true}
                                inputProps={{
                                    name: "team_id",
                                }}
                            >
                                {this.props.teams.map((theTeam) => <MenuItem key={theTeam.id} value={theTeam.id}>{theTeam.name}</MenuItem>)}
                            </Select>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.submitAddPlayoffDivisionTeam}>
                                Add Team
                            </Button>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            {(this.props.divisionTeams.length > 0) ? (
                                <Table
                                    tableHeaderColor="primary"
                                    tableHead={["Team", "Actions"]}
                                    tableData={this.props.divisionTeams.map((theDivisionTeam) => [
                                        this.getTeamName(theDivisionTeam.team_id), 
                                        (
                                            <div>
                                                <Icon className="fa fa-trash manager-table-remove" onClick={() => this.submitRemovePlayoffDivisionTeam(this.state.selectedDivision, theDivisionTeam.team_id)} />
                                            </div>
                                        )
                                    ])}
                                />
                            ) : (
                                <Typography className="modal-noresults">
                                    No division teams.
                                </Typography>
                            )}
                        </GridItem>
                    </GridContainer>
                </div>
            </Modal>
        )
    }

    renderRoundBreakdown() {
        // show the breakdown based on the playoff round
        return (
            <div>
                {this.props.playoffRounds.map((round) => {

                    return (
                        <div key={round.id}>
                            <Typography variant="h3" className="team-header">
                                {round.name}: {this.printPlayoffRoundType(round)}
                            </Typography>

                            {this.renderBreakdownByType(round)}
                            {this.renderPlayoffAddButton(round)}
                        </div>
                    )
                })}
            </div>
        );
    }

    renderBreakdownByType(round) {
        if (round.type == "round_robin") {
            if (round.divisions.length <= 0) {
                return (
                    <CardBody>
                        <p>No divisions.</p>
                    </CardBody>
                );
            }
            else {
                return (
                    <Table
                        tableHeaderColor="primary"
                        tableHead={["Name", "Actions"]}
                        tableData={round.divisions.map((theDivision) => [
                            theDivision.name, 
                            (
                                <div>
                                    <Icon className="fa fa-users manager-table-icon manager-table-edit" onClick={() => this.toggleRoundDivisionTeamsModal(true, theDivision)} />
                                    <Icon className="fa fa-pen manager-table-icon manager-table-edit" onClick={() => this.toggleRoundDivisionUpdateModal(true, theDivision)} />
                                    <Icon className="fa fa-trash manager-table-remove" onClick={() => this.removePlayoffDivision(theDivision)} />
                                </div>
                            )
                        ])}
                    />
                );
            }
        }
        else {
            if (round.matchups.length <= 0) {
                return (
                    <CardBody>
                        <p>No matchups.</p>
                    </CardBody>
                );
            }
            else {
                return (
                    <Table
                        tableHeaderColor="primary"
                        tableHead={["Name", "Away", "", "Home", "Actions"]}
                        tableData={round.matchups.map((theMatchup) => [
                            theMatchup.name, 
                            this.getTeamName(theMatchup.away_team_id),
                            "@",
                            this.getTeamName(theMatchup.home_team_id),
                            (
                                <div>
                                    {/* <Link to={'/'+this.props.match.params.id+'/playoffs/'+round.id+}><Icon className="fa fa-users manager-table-icon manager-table-players" /></Link> */}
                                    <Icon className="fa fa-pen manager-table-icon manager-table-edit" onClick={() => this.toggleRoundMatchupUpdateModal(true, theMatchup)} />
                                    <Icon className="fa fa-trash manager-table-remove" onClick={() => this.removePlayoffMatchup(theMatchup)} />
                                </div>
                            )
                        ])}
                    />
                );
            }
        }
    }

    renderPlayoffAddButton(round) {
        if (round.type == 'round_robin') {
            return (
                <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom table-button-bottom" onClick={() => this.toggleRoundDivisionCreateModal(true, round.id)}>
                    Add Division
                </Button>
            )
        }
        else {
            return (
                <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom table-button-bottom" onClick={() => this.toggleRoundMatchupCreateModal(true, round.id)}>
                    Add Matchup
                </Button>
            )
        }
    }

    printPlayoffRoundType(round) {
        if (round.type == 'round_robin') {
            return "Round Robin";
        }
        else {
            return "Elimination Round";
        }
    }

    renderPlayoffRounds() {
        const { classes } = this.props;

        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                    <Card>
                        <CardHeader color="primary" className="card-header">
                            <p className={classes.cardCategoryWhite}>
                            Rounds
                            </p>
                        </CardHeader>

                        {(this.props.playoffRounds.length <= 0) ? (
                            <CardBody>
                                <p>You don't have any playoff rounds set up yet. You can add a new round by clicking on the Add button below.</p>
                            </CardBody>
                        ) : (
                            <div>
                                <CardBody>
                                    <p>You can update your playoff rounds below, or add a new round by clicking on the Add button below.</p>
                                </CardBody>
                                <Table
                                    tableHeaderColor="primary"
                                    tableHead={["Name", "Type", "Actions"]}
                                    tableData={this.props.playoffRounds.map((round) => [
                                        round.name, 
                                        this.printPlayoffRoundType(round),
                                        (
                                            <div>
                                                <Icon className="fa fa-pen manager-table-icon manager-table-edit" onClick={() => this.toggleRoundUpdateModal(true, round)} />
                                                <Icon className="fa fa-trash manager-table-remove" onClick={() => this.removeRound(round)} />
                                            </div>
                                        )
                                    ])}
                                />
                            </div>
                        )}

                        <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom table-button-bottom" onClick={() => this.toggleRoundCreateModal(true)}>
                            Add Round
                        </Button>
                    </Card>   
                </GridItem>
                <GridItem xs={12} sm={12} md={8}>
                    <Card>
                        <CardHeader color="primary" className="card-header-sec">
                            <p className={classes.cardCategoryWhite}>
                            Round Breakdowns
                            </p>
                        </CardHeader>

                        <CardBody>
                            <p>You can update your playoff rounds below, or add a new division/matchup by clicking on the Add button below.</p>
                        </CardBody>

                        { this.renderRoundBreakdown() }

                    </Card> 
                </GridItem>
            </GridContainer>
        );
    }
    
    render() {
        return (
            <div>
                { this.renderPlayoffRounds() }

                {this.renderCreateRoundModal()}
                {this.renderUpdateRoundModal()}
                {this.renderCreatePlayoffDivisionModal()}
                {this.renderUpdatePlayoffDivisionModal()}
                {this.renderCreatePlayoffMatchupModal()}
                {this.renderUpdatePlayoffMatchupModal()}
                {this.renderPlayoffDivisionTeamsModal()}
            </div>
        );
    }
};

const mapStateToProps = (state) => {

    return {
        isAuthenticated: state.auth.isAuthenticated,
        loading: state.members.loading,
        playoffRounds: state.playoffs.rounds,
        divisionTeams: state.playoffs.selectedDivisionTeams,
        roundForm: state.roundForm,
        divisionForm: state.divisionForm,
        matchupForm: state.matchupForm,
        createPlayoffRoundModalOpen: state.modals.playoffRoundsCreate,
        updatePlayoffRoundModalOpen: state.modals.playoffRoundsUpdate,
        createPlayoffDivisionModalOpen: state.modals.playoffRoundDivisionsCreate,
        updatePlayoffDivisionModalOpen: state.modals.playoffRoundDivisionsUpdate,
        updatePlayoffDivisionTeamsModalOpen: state.modals.playoffRoundDivisionTeams,
        createPlayoffMatchupModalOpen: state.modals.playoffRoundMatchupsCreate,
        updatePlayoffMatchupModalOpen: state.modals.playoffRoundMatchupsUpdate,
        teams: state.teams.teams,
    };
};

export default connect(mapStateToProps)(withStyles(styles)(PlayoffsIndexPage));
