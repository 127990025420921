import axios from 'axios';
import { API_URL } from '../config/constants';
import setAuthToken from '../utils/setAuthToken';
import jwt from 'jsonwebtoken';
import { loginFormReset, loginFormError } from './loginForm';
import setBackgroundPhoto from '../utils/setBackgroundPhoto';
import { setSelectedLeague } from './selectedLeague';

export const setCurrentUser = (user) => {
    return {
        type: 'SET_CURRENT_USER',
        user
    }
}

export const removeCurrentUser = () => {
    return {
        type: 'REMOVE_CURRENT_USER'
    }
}

export const authorize = (data = {}) => {
    return (dispatch) => {

        let token = localStorage.getItem('funleagueToken');

        // is the token present?
        if (token) {
            const decodedToken = jwt.decode(token);
            setAuthToken(token);
            dispatch(setCurrentUser(decodedToken));
        }
    };
}

export const login = (emailAddress, password, history) => {
    return (dispatch) => {
        return axios.post(API_URL+'/api/login', {emailAddress, password})
            .then(function(res) {
                if (res.data.status == 'success') {
                    const token = res.data.token;
                    localStorage.setItem('funleagueToken', token);
                    setAuthToken(token);
                    dispatch(setCurrentUser(jwt.decode(token)));
                    dispatch(setSelectedLeague(res.data.league));
                    dispatch(loginFormReset());
                    setBackgroundPhoto('');
                    history.push('/'+res.data.redirect+'/fields');
                }
                else {
                    dispatch(loginFormError(res.data.message));
                }
            })
            .catch(function(err) {
                console.log(err);
                alert("We're sorry, but there was an issue with authorization. Please try again shortly.");
            });
    };
}

export const logout = () => {
    return (dispatch) => {
        localStorage.removeItem('funleagueToken');
        dispatch(removeCurrentUser());
    };
}