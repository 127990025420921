import axios from 'axios';
import { API_URL } from '../config/constants';
import removeAuthToken from '../utils/removeAuthToken';
import { divisionFormReset, divisionFormError, divisionFormSuccess } from './divisionForm';
import { loadTeams } from './teams';
import { closeDivisionsCreate } from './modals';

export const loadDivisions = (divisions) => ({
    type: 'LOAD_DIVISIONS',
    divisions
});

export const setDivisionsLoading = () => ({
    type: 'SET_DIVISIONS_LOADING'
});

export const addDivision = (id, { name } = {}) => {
    
    return (dispatch) => {

        dispatch(setDivisionsLoading());
        return axios.post(API_URL+'/api/divisions/'+id+'/create', { name })
            .then(function(res) {
                if (res.data.status == 'success') {
                    if (res.data.teams) {
                        dispatch(loadTeams(res.data.teams));
                    }
                    dispatch(loadDivisions(res.data.divisions));
                    dispatch(divisionFormReset());
                    dispatch(closeDivisionsCreate());
                }
                else {
                    dispatch(divisionFormError(res.data.message));
                }
            })
            .catch(function(err) {
                
                // token is likely invalid; remove it and prompt the user to login again
                console.log(err);
                dispatch(divisionFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const updateDivision = (id, did, { name } = {}) => {
    
    return (dispatch) => {

        dispatch(setDivisionsLoading());
        return axios.put(API_URL+'/api/divisions/'+id+'/'+did, { name })
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(loadDivisions(res.data.divisions));
                    dispatch(divisionFormSuccess('division Updated!'));
                }
                else {
                    dispatch(divisionFormError(res.data.message));
                    console.log(res.data);
                    console.log("Could not add your division!");
                }
            })
            .catch(function(err) {

                // token is likely invalid; remove it and prompt the user to login again
                dispatch(divisionFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const removeDivision = (did, id) => {

    return (dispatch) => {

        dispatch(setDivisionsLoading());
        return axios.delete(API_URL+'/api/divisions/'+id+'/'+did, {})
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(loadDivisions(res.data.divisions));
                    dispatch(loadTeams(res.data.teams));
                }
                else {
                    // dispatch(divisionFormError(res.data.message));
                    console.log(res.data);
                    console.log("Could not remove your division!");
                }
            })
            .catch(function(err) {

                // token is likely invalid; remove it and prompt the user to login again
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
}