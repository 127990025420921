import axios from 'axios';
import { API_URL } from '../config/constants';
import removeAuthToken from '../utils/removeAuthToken';
import { memberFormReset, memberFormError, memberFormSuccess } from '../actions/memberForm';
import { closeMembersCreate } from './modals';
import { setSelectedLeague } from './selectedLeague';

export const setMembers = (members) => ({
    type: 'SET_MEMBERS',
    members
});

export const setMembersLoading = () => ({
    type: 'SET_MEMBERS_LOADING'
});

export const getLeagueMembers = (id) => {

    return (dispatch) => {

        dispatch(setMembersLoading());
        return axios.get(API_URL+'/api/members/'+id)
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(setMembers(res.data.members));
                    dispatch(setSelectedLeague(res.data.league));
                }
                else {
                    console.log(res.data);
                    console.log("Could not get members!");
                }
            })
            .catch(function(err) {
                
                // token is likely invalid; remove it and prompt the user to login again
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

// export const getGroupMembers = (id, gid) => {

//     return (dispatch) => {

//         dispatch(setMembersLoading());
//         return axios.get(API_URL+'/api/members/'+id+'/'+gid)
//             .then(function(res) {
//                 if (res.data.status == 'success') {
//                     dispatch(setMembers(res.data.members));
//                 }
//                 else {
//                     console.log(res.data);
//                     console.log("Could not get members!");
//                 }
//             })
//             .catch(function(err) {
                
//                 // token is likely invalid; remove it and prompt the user to login again
//                 if (err.response.status === 401) {
//                     removeAuthToken(dispatch);
//                 }
                
//             });
//     };
// };

export const addMember = (id, { email, role } = {}) => {
    
    return (dispatch) => {

        dispatch(setMembersLoading());
        return axios.post(API_URL+'/api/members/'+id+'/create', { email, role })
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(setMembers(res.data.members));
                    dispatch(memberFormReset());
                    dispatch(closeMembersCreate());
                }
                else {
                    dispatch(memberFormError(res.data.message));
                }
            })
            .catch(function(err) {
                
                // token is likely invalid; remove it and prompt the user to login again
                dispatch(memberFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const updateMember = (id, mid, { role } = {}) => {
    
    return (dispatch) => {

        dispatch(setMembersLoading());
        return axios.put(API_URL+'/api/members/'+id+'/'+mid, { role })
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(setMembers(res.data.members));
                    dispatch(memberFormSuccess('Member Role Updated!'));
                }
                else {
                    dispatch(memberFormError(res.data.message));
                    console.log(res.data);
                    console.log("Could not add your member!");
                }
            })
            .catch(function(err) {

                // token is likely invalid; remove it and prompt the user to login again
                dispatch(memberFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const removeMember = (mid, id) => {

    return (dispatch) => {

        dispatch(setMembersLoading());
        return axios.delete(API_URL+'/api/members/'+id+'/'+mid, {})
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(setMembers(res.data.members));
                }
                else {
                    // dispatch(memberFormError(res.data.message));
                    console.log(res.data);
                    console.log("Could not remove your member!");
                }
            })
            .catch(function(err) {

                // token is likely invalid; remove it and prompt the user to login again
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
}