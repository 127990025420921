import React from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from '@material-ui/core/Icon';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

import GridItem from "./common/GridItem";
import GridContainer from "./common/GridContainer";
import Table from "./common/Table";
import Card from "./common/Card";
import CardHeader from "./common/CardHeader";
import CardBody from "./common/CardBody";
import verifyLogin from '../utils/verifyLogin';
import { addDivision, updateDivision, removeDivision } from '../actions/divisions';
import { divisionFormUpdate, divisionFormError, divisionFormReset } from '../actions/divisionForm';
import { getLeagueTeams, addTeams, updateTeam, removeTeam } from '../actions/teams';
import { teamFormUpdate, teamFormError, teamFormReset } from '../actions/teamForm';
import { 
    openDivisionsCreate, 
    closeDivisionsCreate, 
    openDivisionsUpdate, 
    closeDivisionsUpdate,
    openTeamsCreate, 
    closeTeamsCreate, 
    openTeamsUpdate, 
    closeTeamsUpdate } from '../actions/modals';
import { setSelectedLeagueID } from '../actions/selectedLeague';


// import setAuthToken from '../utils/setAuthToken';

const styles = {
    cardCategoryWhite: {
      "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
      },
      "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
      }
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
      }
    }
};


class TeamsIndexPage extends React.Component {

    constructor(props) {
        super(props);

        this.toggleDivisionCreateModal = this.toggleDivisionCreateModal.bind(this);
        this.toggleDivisionUpdateModal = this.toggleDivisionUpdateModal.bind(this);
        this.submitCreateDivision = this.submitCreateDivision.bind(this);
        this.submitUpdateDivision = this.submitUpdateDivision.bind(this);
        this.toggleTeamCreateModal = this.toggleTeamCreateModal.bind(this);
        this.toggleTeamUpdateModal = this.toggleTeamUpdateModal.bind(this);
        this.submitCreateTeam = this.submitCreateTeam.bind(this);
        this.submitUpdateTeam = this.submitUpdateTeam.bind(this);

        this.state = {
            selectedDivision: {},
            selectedTeam: {}
        };
    }

    componentDidMount() {
        this.props.dispatch(setSelectedLeagueID(this.props.match.params.id));
        verifyLogin(this.props.history);

        this.props.dispatch(getLeagueTeams(this.props.match.params.id));
    }

    componentDidUpdate() {
        verifyLogin(this.props.history);
    }

    toggleDivisionCreateModal(isOpen) {
        if (isOpen) {
            this.props.dispatch(divisionFormReset());
            this.props.dispatch(openDivisionsCreate());
        }
        else {
            this.props.dispatch(closeDivisionsCreate());
        }
    }

    toggleDivisionUpdateModal(isOpen, selectedDivision) {
        if (isOpen) {
            this.setState(() => ({
                selectedDivision
            }));
            this.props.dispatch(divisionFormReset());
            this.props.dispatch(divisionFormUpdate({ prop: 'name', value: selectedDivision.name }));
            this.props.dispatch(openDivisionsUpdate());
        }
        else {
            this.props.dispatch(closeDivisionsUpdate());
        }
    }

    toggleTeamCreateModal(isOpen, setDivision = 0) {
        if (isOpen) {
            if (setDivision != 0) {
                this.props.dispatch(teamFormUpdate({ prop: 'division_id', value: setDivision }));
            }
            this.props.dispatch(teamFormReset());
            this.props.dispatch(openTeamsCreate());
        }
        else {
            this.props.dispatch(closeTeamsCreate());
        }
    }

    toggleTeamUpdateModal(isOpen, selectedTeam) {
        if (isOpen) {
            this.setState(() => ({
                selectedTeam
            }));
            this.props.dispatch(teamFormReset());
            this.props.dispatch(teamFormUpdate({ prop: 'division_id', value: selectedTeam.division_id }));
            this.props.dispatch(teamFormUpdate({ prop: 'manager_name', value: selectedTeam.manager_name }));
            this.props.dispatch(teamFormUpdate({ prop: 'manager_phone', value: selectedTeam.manager_phone }));
            this.props.dispatch(teamFormUpdate({ prop: 'name', value: selectedTeam.name }));
            this.props.dispatch(openTeamsUpdate());
        }
        else {
            this.props.dispatch(closeTeamsUpdate());
        }
    }

    submitCreateDivision() {

        // perform some error checking
        if (this.props.divisionForm.name.trim() == "") {

            // required fields aren't present
            this.props.dispatch(divisionFormError("Please enter a division name."));
        }
        else {

            // add the division
            this.props.dispatch(addDivision(this.props.match.params.id, this.props.divisionForm));
        }
    }

    submitUpdateDivision() {

        // perform some error checking
        if (this.props.divisionForm.name.trim() == "") {

            // required fields aren't present
            this.props.dispatch(divisionFormError("Please enter a division name."));
        }
        else {

            // add the committee member
            this.props.dispatch(updateDivision(this.props.match.params.id, this.state.selectedDivision.id, this.props.divisionForm));
        }
    }

    removeDivision({ id }) {
        if (confirm("Are you sure you want to remove this division? This cannot be undone, and it will also remove any teams associated with the division.")) {
            this.props.dispatch(removeDivision(id, this.props.match.params.id));
        };
    }

    submitCreateTeam() {

        // perform some error checking
        if (this.props.teamForm.name.trim() == "" && this.props.teamForm.teams.trim() == "") {

            console.log(this.props.teamForm);
            // required fields aren't present
            this.props.dispatch(teamFormError("Please enter at least one team name."));
        }
        else {

            // add the team
            this.props.dispatch(addTeams(this.props.match.params.id, this.props.teamForm));
        }
    }

    submitUpdateTeam() {

        // perform some error checking
        if (this.props.teamForm.name.trim() == "") {

            // required fields aren't present
            this.props.dispatch(teamFormError("Please enter a team name."));
        }
        else {

            // add the team
            this.props.dispatch(updateTeam(this.props.match.params.id, this.state.selectedTeam.id, this.props.teamForm));
        }
    }

    removeTeam({ id }) {
        if (confirm("Are you sure you want to remove this team? This cannot be undone.")) {
            this.props.dispatch(removeTeam(id, this.props.match.params.id));
        };
    }

    renderCreateDivisionModal() {
        const { classes } = this.props;

        return (
            <Modal
                aria-labelledby="division-add"
                aria-describedby="division-add"
                open={this.props.createDivisionModalOpen}
                onClose={() => this.toggleDivisionCreateModal(false)}
                className="modal_admin"
            >
                <div className="modal-member">
                    <Typography variant="h4" className="modal-title">
                        Add a Division
                    </Typography>
                    <Typography className="modal-description">
                        Please add the division name, then click 'Add Division'.
                    </Typography>

                    { this.props.divisionForm.error && (
                        <Typography className="modal-error">
                            { this.props.divisionForm.error }
                        </Typography>
                    )}

                    <TextField
                        id="create-division-name"
                        label="Name"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.divisionForm.name}
                        onChange={(e) => { this.props.dispatch(divisionFormUpdate({ prop: 'name', value: e.target.value })); }}
                        margin="normal"
                    />

                    <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.submitCreateDivision}>
                        Add Division
                    </Button>
                </div>
            </Modal>
        )
    }

    renderUpdateDivisionModal() {
        return (
            <Modal
                aria-labelledby="division-update"
                aria-describedby="division-update"
                open={this.props.updateDivisionModalOpen}
                onClose={() => this.toggleDivisionUpdateModal(false)}
                className="modal_admin"
            >
                <div className="modal-member">
                    <Typography variant="h4" className="modal-title">
                        Update Division
                    </Typography>
                    <Typography className="modal-description">
                        You can update the division name if you like, then click 'Update Division'.
                    </Typography>

                    { this.props.divisionForm.error && (
                        <Typography className="modal-error">
                            { this.props.divisionForm.error }
                        </Typography>
                    )}

                    { this.props.divisionForm.message && (
                        <Typography className="modal-success">
                            { this.props.divisionForm.message }
                        </Typography>
                    )}

                    <TextField
                        id="update-division-name"
                        label="Name"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.divisionForm.name}
                        onChange={(e) => { this.props.dispatch(divisionFormUpdate({ prop: 'name', value: e.target.value })); }}
                        margin="normal"
                    />

                    <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.submitUpdateDivision}>
                        Update Division
                    </Button>
                </div>
            </Modal>
        )
    }

    renderCreateTeamsModal() {
        const { classes } = this.props;

        return (
            <Modal
                aria-labelledby="team-add"
                aria-describedby="team-add"
                open={this.props.createTeamsModalOpen}
                onClose={() => this.toggleTeamCreateModal(false)}
                className="modal_admin"
            >
                <div className="modal-member">
                    <Typography variant="h4" className="modal-title">
                        Add Teams
                    </Typography>
                    <Typography className="modal-description">
                        Please add each team name on a separate line, than click 'Add Teams'.
                    </Typography>

                    { this.props.teamForm.error && (
                        <Typography className="modal-error">
                            { this.props.teamForm.error }
                        </Typography>
                    )}

                    { (this.props.divisions.length > 0) && (
                        <FormControl className="modal-select">
                            <InputLabel htmlFor="division_id">Division ID</InputLabel>
                            <Select
                                value={this.props.teamForm.division_id}
                                onChange={(e) => { this.props.dispatch(teamFormUpdate({ prop: 'division_id', value: e.target.value })); }}
                                fullWidth={true}
                                inputProps={{
                                    name: 'division_id',
                                    id: 'create-team-division_id',
                                }}
                            >
                                {this.props.divisions.map((division) => <MenuItem key={division.id} value={division.id}>{division.name}</MenuItem>)}
                            </Select>
                        </FormControl>
                    )}

                    <TextField
                        id="create-team-names"
                        label="Name"
                        multiline
                        rows="4"
                        rowsMax="12"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.teamForm.title}
                        onChange={(e) => { this.props.dispatch(teamFormUpdate({ prop: 'teams', value: e.target.value })); }}
                        margin="normal"
                    />

                    <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.submitCreateTeam}>
                        Add Teams
                    </Button>
                </div>
            </Modal>
        )
    }

    renderUpdateTeamModal() {
        return (
            <Modal
                aria-labelledby="team-update"
                aria-describedby="team-update"
                open={this.props.updateTeamModalOpen}
                onClose={() => this.toggleTeamUpdateModal(false)}
                className="modal_admin"
            >
                <div className="modal-member">
                    <Typography variant="h4" className="modal-title">
                        Update Team
                    </Typography>
                    <Typography className="modal-description">
                        Please select the updated user role, and click the update button to continue.
                    </Typography>

                    { this.props.teamForm.error && (
                        <Typography className="modal-error">
                            { this.props.teamForm.error }
                        </Typography>
                    )}

                    { this.props.teamForm.message && (
                        <Typography className="modal-success">
                            { this.props.teamForm.message }
                        </Typography>
                    )}

                    { (this.props.divisions.length > 0) && (
                        <FormControl className="modal-select">
                            <InputLabel htmlFor="division_id">Division ID</InputLabel>
                            <Select
                                value={this.props.teamForm.division_id}
                                onChange={(e) => { this.props.dispatch(teamFormUpdate({ prop: 'division_id', value: e.target.value })); }}
                                fullWidth={true}
                                inputProps={{
                                    name: 'division_id',
                                    id: 'update-team-division_id',
                                }}
                            >
                                {this.props.divisions.map((division) => <MenuItem key={division.id} value={division.id}>{division.name}</MenuItem>)}
                            </Select>
                        </FormControl>
                    )}

                    <TextField
                        id="update-team-name"
                        label="Name"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.teamForm.name}
                        onChange={(e) => { this.props.dispatch(teamFormUpdate({ prop: 'name', value: e.target.value })); }}
                        margin="normal"
                    />

                    <TextField
                        id="update-team-manager-name"
                        label="Manager Name"
                        className="modal-input"
                        fullWidth={true}
                        multiline={true}
                        value={this.props.teamForm.manager_name}
                        onChange={(e) => { this.props.dispatch(teamFormUpdate({ prop: 'manager_name', value: e.target.value })); }}
                        margin="normal"
                    />

                    <TextField
                        id="update-team-manager-phone"
                        label="Manager Phone"
                        className="modal-input"
                        fullWidth={true}
                        multiline={true}
                        value={this.props.teamForm.manager_phone}
                        onChange={(e) => { this.props.dispatch(teamFormUpdate({ prop: 'manager_phone', value: e.target.value })); }}
                        margin="normal"
                    />

                    <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.submitUpdateTeam}>
                        Update Team
                    </Button>
                </div>
            </Modal>
        )
    }

    renderTeamsList() {
        console.log(this.props.teams);
        if (this.props.divisions.length > 0) {

            // show the teams based on the division
            return (
                <div>
                    {this.props.divisions.map((division) => {

                        // get the divisional teams
                        const divisionTeams = this.props.teams.filter((team) => team.division_id == division.id);
                    
                        return (
                            <div key={division.id}>
                                <Typography variant="h3" className="team-header">
                                    {division.name}
                                </Typography>

                                {(divisionTeams.length <= 0) ? (
                                    <CardBody>
                                        <p>No teams.</p>
                                    </CardBody>
                                ) : (
                                    <Table
                                        tableHeaderColor="primary"
                                        tableHead={["Name", "Actions"]}
                                        tableData={divisionTeams.map((theTeam) => [
                                            theTeam.name, 
                                            (
                                                <div>
                                                    <Link to={'/'+this.props.match.params.id+'/players/'+theTeam.id}><Icon className="fa fa-users manager-table-icon manager-table-players" /></Link>
                                                    <Icon className="fa fa-pen manager-table-icon manager-table-edit" onClick={() => this.toggleTeamUpdateModal(true, theTeam)} />
                                                    <Icon className="fa fa-trash manager-table-remove" onClick={() => this.removeTeam(theTeam)} />
                                                </div>
                                            )
                                        ])}
                                    />
                                )}

                                <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom table-button-bottom" onClick={() => this.toggleTeamCreateModal(true, division.id)}>
                                    Add Division Teams
                                </Button>
                            </div>
                        )
                    })}
                </div>
            );
        }
        else {

            // just show the full list of teams
            return (
                <div>

                    {(this.props.teams.length <= 0) ? (
                        <CardBody>
                            <p>No teams.</p>
                        </CardBody>
                    ) : (
                        <Table
                            tableHeaderColor="primary"
                            tableHead={["Name", "Actions"]}
                            tableData={this.props.teams.map((theTeam) => [
                                theTeam.name, 
                                (
                                    <div>
                                        <Icon className="fa fa-pen manager-table-icon manager-table-edit" onClick={() => this.toggleTeamUpdateModal(true, theTeam)} />
                                        <Icon className="fa fa-trash manager-table-remove" onClick={() => this.removeTeam(theTeam)} />
                                    </div>
                                )
                            ])}
                        />
                    )}

                    <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom table-button-bottom" onClick={() => this.toggleTeamCreateModal(true)}>
                        Add Teams
                    </Button>
                </div>
            );
        }
    }

    renderDivisionsAndTeams() {
        const { classes } = this.props;

        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                    <Card>
                        <CardHeader color="primary" className="card-header">
                            <p className={classes.cardCategoryWhite}>
                            Divisions
                            </p>
                        </CardHeader>

                        {(this.props.divisions.length <= 0) ? (
                            <CardBody>
                                <p>You don't have any divisions set up yet. You can add a new division by clicking on the Add button below.</p>
                            </CardBody>
                        ) : (
                            <div>
                                <CardBody>
                                    <p>You can update your divisions below, or add a new division by clicking on the Add button below.</p>
                                </CardBody>
                                <Table
                                    tableHeaderColor="primary"
                                    tableHead={["Name", "Actions"]}
                                    tableData={this.props.divisions.map((division) => [
                                        division.name, 
                                        (
                                            <div>
                                                <Icon className="fa fa-pen manager-table-icon manager-table-edit" onClick={() => this.toggleDivisionUpdateModal(true, division)} />
                                                <Icon className="fa fa-trash manager-table-remove" onClick={() => this.removeDivision(division)} />
                                            </div>
                                        )
                                    ])}
                                />
                            </div>
                        )}

                        <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom table-button-bottom" onClick={() => this.toggleDivisionCreateModal(true)}>
                            Add Division
                        </Button>
                    </Card>   
                </GridItem>
                <GridItem xs={12} sm={12} md={8}>
                    <Card>
                        <CardHeader color="primary" className="card-header-sec">
                            <p className={classes.cardCategoryWhite}>
                            Teams
                            </p>
                        </CardHeader>

                        <CardBody>
                            <p>You can update your teams below, or add a new team by clicking on the Add button below.</p>
                        </CardBody>

                        {this.renderTeamsList()}

                    </Card> 
                </GridItem>
            </GridContainer>
        );
    }
    
    render() {
        return (
            <div>
                { this.renderDivisionsAndTeams() }

                {this.renderCreateDivisionModal()}
                {this.renderUpdateDivisionModal()}
                {this.renderCreateTeamsModal()}
                {this.renderUpdateTeamModal()}
            </div>
        );
    }
};

const mapStateToProps = (state) => {

    return {
        isAuthenticated: state.auth.isAuthenticated,
        loading: state.members.loading,
        divisions: state.divisions.divisions,
        divisionForm: state.divisionForm,
        teams: state.teams.teams,
        teamForm: state.teamForm,
        createDivisionModalOpen: state.modals.divisionsCreate,
        updateDivisionModalOpen: state.modals.divisionsUpdate,
        createTeamsModalOpen: state.modals.teamsCreate,
        updateTeamModalOpen: state.modals.teamsUpdate,
    };
};

export default connect(mapStateToProps)(withStyles(styles)(TeamsIndexPage));
