const modalsDefaultState = {
    membersCreate: false,
    membersUpdate: false,
    rulesCreate: false,
    rulesUpdate: false,
    fieldsCreate: false,
    fieldsUpdate: false,
    divisionsCreate: false,
    divisionsUpdate: false,
    teamsCreate: false,
    teamsUpdate: false,
    playersCreate: false,
    playersUpdate: false,
    gamesCreate: false,
    gamesUpdate: false,
    scoresUpdate: false,
    playoffRoundsCreate: false,
    playoffRoundsUpdate: false,
    playoffRoundDivisionsCreate: false,
    playoffRoundDivisionsUpdate: false,
    playoffRoundDivisionTeams: false,
    playoffRoundMatchupsCreate: false,
    playoffRoundMatchupsUpdate: false,
};

export default (state = modalsDefaultState, action) => {
    switch (action.type) {

        case 'OPEN_MEMBERS_CREATE_MODAL':
            return {
                ...state,
                membersCreate: true
            };

        case 'CLOSE_MEMBERS_CREATE_MODAL':
            return {
                ...state,
                membersCreate: false
            };

        case 'OPEN_MEMBERS_UPDATE_MODAL':
            return {
                ...state,
                membersUpdate: true
            };

        case 'CLOSE_MEMBERS_UPDATE_MODAL':
            return {
                ...state,
                membersUpdate: false
            };

        case 'OPEN_RULES_CREATE_MODAL':
            return {
                ...state,
                rulesCreate: true
            };

        case 'CLOSE_RULES_CREATE_MODAL':
            return {
                ...state,
                rulesCreate: false
            };

        case 'OPEN_RULES_UPDATE_MODAL':
            return {
                ...state,
                rulesUpdate: true
            };

        case 'CLOSE_RULES_UPDATE_MODAL':
            return {
                ...state,
                rulesUpdate: false
            };

        case 'OPEN_FIELDS_CREATE_MODAL':
            return {
                ...state,
                fieldsCreate: true
            };

        case 'CLOSE_FIELDS_CREATE_MODAL':
            return {
                ...state,
                fieldsCreate: false
            };

        case 'OPEN_FIELDS_UPDATE_MODAL':
            return {
                ...state,
                fieldsUpdate: true
            };

        case 'CLOSE_FIELDS_UPDATE_MODAL':
            return {
                ...state,
                fieldsUpdate: false
            };

        case 'OPEN_DIVISIONS_CREATE_MODAL':
            return {
                ...state,
                divisionsCreate: true
            };

        case 'CLOSE_DIVISIONS_CREATE_MODAL':
            return {
                ...state,
                divisionsCreate: false
            };

        case 'OPEN_DIVISIONS_UPDATE_MODAL':
            return {
                ...state,
                divisionsUpdate: true
            };

        case 'CLOSE_DIVISIONS_UPDATE_MODAL':
            return {
                ...state,
                divisionsUpdate: false
            };

        case 'OPEN_TEAMS_CREATE_MODAL':
            return {
                ...state,
                teamsCreate: true
            };

        case 'CLOSE_TEAMS_CREATE_MODAL':
            return {
                ...state,
                teamsCreate: false
            };

        case 'OPEN_TEAMS_UPDATE_MODAL':
            return {
                ...state,
                teamsUpdate: true
            };

        case 'CLOSE_TEAMS_UPDATE_MODAL':
            return {
                ...state,
                teamsUpdate: false
            };

        case 'OPEN_PLAYERS_CREATE_MODAL':
            return {
                ...state,
                playersCreate: true
            };

        case 'CLOSE_PLAYERS_CREATE_MODAL':
            return {
                ...state,
                playersCreate: false
            };

        case 'OPEN_PLAYERS_UPDATE_MODAL':
            return {
                ...state,
                playersUpdate: true
            };

        case 'CLOSE_PLAYERS_UPDATE_MODAL':
            return {
                ...state,
                playersUpdate: false
            };

        case 'OPEN_GAMES_CREATE_MODAL':
            return {
                ...state,
                gamesCreate: true
            };

        case 'CLOSE_GAMES_CREATE_MODAL':
            return {
                ...state,
                gamesCreate: false
            };

        case 'OPEN_GAMES_UPDATE_MODAL':
            return {
                ...state,
                gamesUpdate: true
            };

        case 'CLOSE_GAMES_UPDATE_MODAL':
            return {
                ...state,
                gamesUpdate: false
            };

        case 'OPEN_SCORES_CREATE_MODAL':
            return {
                ...state,
                scoresCreate: true
            };

        case 'CLOSE_SCORES_CREATE_MODAL':
            return {
                ...state,
                scoresCreate: false
            };

        case 'OPEN_SCORES_UPDATE_MODAL':
            return {
                ...state,
                scoresUpdate: true
            };

        case 'CLOSE_SCORES_UPDATE_MODAL':
            return {
                ...state,
                scoresUpdate: false
            };

        case 'OPEN_ROUNDS_CREATE_MODAL':
            return {
                ...state,
                playoffRoundsCreate: true
            };

        case 'CLOSE_ROUNDS_CREATE_MODAL':
            return {
                ...state,
                playoffRoundsCreate: false
            };

        case 'OPEN_ROUNDS_UPDATE_MODAL':
            return {
                ...state,
                playoffRoundsUpdate: true
            };

        case 'CLOSE_ROUNDS_UPDATE_MODAL':
            return {
                ...state,
                playoffRoundsUpdate: false
            };

        case 'OPEN_ROUND_DIVISIONS_CREATE_MODAL':
            return {
                ...state,
                playoffRoundDivisionsCreate: true
            };

        case 'CLOSE_ROUND_DIVISIONS_CREATE_MODAL':
            return {
                ...state,
                playoffRoundDivisionsCreate: false
            };

        case 'OPEN_ROUND_DIVISIONS_UPDATE_MODAL':
            return {
                ...state,
                playoffRoundDivisionsUpdate: true
            };

        case 'CLOSE_ROUND_DIVISIONS_UPDATE_MODAL':
            return {
                ...state,
                playoffRoundDivisionsUpdate: false
            };

        case 'OPEN_ROUND_MATCHUPS_CREATE_MODAL':
            return {
                ...state,
                playoffRoundMatchupsCreate: true
            };

        case 'CLOSE_ROUND_MATCHUPS_CREATE_MODAL':
            return {
                ...state,
                playoffRoundMatchupsCreate: false
            };

        case 'OPEN_ROUND_MATCHUPS_UPDATE_MODAL':
            return {
                ...state,
                playoffRoundMatchupsUpdate: true
            };

        case 'CLOSE_ROUND_MATCHUPS_UPDATE_MODAL':
            return {
                ...state,
                playoffRoundMatchupsUpdate: false
            };

        case 'OPEN_ROUND_DIVISION_TEAMS_MODAL':
            return {
                ...state,
                playoffRoundDivisionTeams: true
            };

        case 'CLOSE_ROUND_DIVISION_TEAMS_MODAL':
            return {
                ...state,
                playoffRoundDivisionTeams: false
            };

        case 'RESET_MODALS':
            return modalsDefaultState;

        default:
            return state;
    }
};