import { createStore, applyMiddleware, combineReducers } from 'redux';
import ReduxThunk from 'redux-thunk';
import authReducer from '../reducers/auth';
import loginFormReducer from '../reducers/loginForm';
import sidebarReducer from '../reducers/sidebar';
import modalsReducer from '../reducers/modals';
import selectedLeagueReducer from '../reducers/selectedLeague';
import membersReducer from '../reducers/members';
import memberFormReducer from '../reducers/memberForm';
import rulesReducer from '../reducers/rules';
import ruleFormReducer from '../reducers/ruleForm';
import fieldsReducer from '../reducers/fields';
import fieldFormReducer from '../reducers/fieldForm';
import divisionsReducer from '../reducers/divisions';
import divisionFormReducer from '../reducers/divisionForm';
import teamsReducer from '../reducers/teams';
import teamFormReducer from '../reducers/teamForm';
import playersReducer from '../reducers/players';
import playerFormReducer from '../reducers/playerForm';
import gamesReducer from '../reducers/games';
import gameFormReducer from '../reducers/gameForm';
import playoffsReducer from '../reducers/playoffs';
import roundFormReducer from '../reducers/roundForm';
import matchupFormReducer from '../reducers/matchupForm';

export default () => {
    const store = createStore(
        combineReducers({
            auth: authReducer,
            loginForm: loginFormReducer,
            sidebar: sidebarReducer,
            modals: modalsReducer,
            members: membersReducer,
            memberForm: memberFormReducer,
            rules: rulesReducer,
            ruleForm: ruleFormReducer,
            fields: fieldsReducer,
            fieldForm: fieldFormReducer,
            selectedLeague: selectedLeagueReducer,
            divisions: divisionsReducer,
            divisionForm: divisionFormReducer,
            teams: teamsReducer,
            teamForm: teamFormReducer,
            players: playersReducer,
            playerForm: playerFormReducer,
            games: gamesReducer,
            gameForm: gameFormReducer,
            playoffs: playoffsReducer,
            roundForm: roundFormReducer,
            matchupForm: matchupFormReducer,
        }),
        {},
        applyMiddleware(ReduxThunk)
    );

    return store;
}