export const openMembersCreate = () => {

    return {
        type: 'OPEN_MEMBERS_CREATE_MODAL',
    };
};

export const closeMembersCreate = () => {

    return {
        type: 'CLOSE_MEMBERS_CREATE_MODAL',
    };
};

export const openMembersUpdate = () => {

    return {
        type: 'OPEN_MEMBERS_UPDATE_MODAL',
    };
};

export const closeMembersUpdate = () => {

    return {
        type: 'CLOSE_MEMBERS_UPDATE_MODAL',
    };
};

export const openRulesCreate = () => {

    return {
        type: 'OPEN_RULES_CREATE_MODAL',
    };
};

export const closeRulesCreate = () => {

    return {
        type: 'CLOSE_RULES_CREATE_MODAL',
    };
};

export const openRulesUpdate = () => {

    return {
        type: 'OPEN_RULES_UPDATE_MODAL',
    };
};

export const closeRulesUpdate = () => {

    return {
        type: 'CLOSE_RULES_UPDATE_MODAL',
    };
};

export const openFieldsCreate = () => {

    return {
        type: 'OPEN_FIELDS_CREATE_MODAL',
    };
};

export const closeFieldsCreate = () => {

    return {
        type: 'CLOSE_FIELDS_CREATE_MODAL',
    };
};

export const openFieldsUpdate = () => {

    return {
        type: 'OPEN_FIELDS_UPDATE_MODAL',
    };
};

export const closeFieldsUpdate = () => {

    return {
        type: 'CLOSE_FIELDS_UPDATE_MODAL',
    };
};

export const openDivisionsCreate = () => {

    return {
        type: 'OPEN_DIVISIONS_CREATE_MODAL',
    };
};

export const closeDivisionsCreate = () => {

    return {
        type: 'CLOSE_DIVISIONS_CREATE_MODAL',
    };
};

export const openDivisionsUpdate = () => {

    return {
        type: 'OPEN_DIVISIONS_UPDATE_MODAL',
    };
};

export const closeDivisionsUpdate = () => {

    return {
        type: 'CLOSE_DIVISIONS_UPDATE_MODAL',
    };
};

export const openTeamsCreate = () => {

    return {
        type: 'OPEN_TEAMS_CREATE_MODAL',
    };
};

export const closeTeamsCreate = () => {

    return {
        type: 'CLOSE_TEAMS_CREATE_MODAL',
    };
};

export const openTeamsUpdate = () => {

    return {
        type: 'OPEN_TEAMS_UPDATE_MODAL',
    };
};

export const closeTeamsUpdate = () => {

    return {
        type: 'CLOSE_TEAMS_UPDATE_MODAL',
    };
};

export const openPlayersCreate = () => {

    return {
        type: 'OPEN_PLAYERS_CREATE_MODAL',
    };
};

export const closePlayersCreate = () => {

    return {
        type: 'CLOSE_PLAYERS_CREATE_MODAL',
    };
};

export const openPlayersUpdate = () => {

    return {
        type: 'OPEN_PLAYERS_UPDATE_MODAL',
    };
};

export const closePlayersUpdate = () => {

    return {
        type: 'CLOSE_PLAYERS_UPDATE_MODAL',
    };
};

export const openGamesCreate = () => {

    return {
        type: 'OPEN_GAMES_CREATE_MODAL',
    };
};

export const closeGamesCreate = () => {

    return {
        type: 'CLOSE_GAMES_CREATE_MODAL',
    };
};

export const openGamesUpdate = () => {

    return {
        type: 'OPEN_GAMES_UPDATE_MODAL',
    };
};

export const closeGamesUpdate = () => {

    return {
        type: 'CLOSE_GAMES_UPDATE_MODAL',
    };
};

export const openScoresCreate = () => {

    return {
        type: 'OPEN_SCORES_CREATE_MODAL',
    };
};

export const closeScoresCreate = () => {

    return {
        type: 'CLOSE_SCORES_CREATE_MODAL',
    };
};

export const openScoresUpdate = () => {

    return {
        type: 'OPEN_SCORES_UPDATE_MODAL',
    };
};

export const closeScoresUpdate = () => {

    return {
        type: 'CLOSE_SCORES_UPDATE_MODAL',
    };
};

export const openRoundsCreate = () => {

    return {
        type: 'OPEN_ROUNDS_CREATE_MODAL',
    };
};

export const closeRoundsCreate = () => {

    return {
        type: 'CLOSE_ROUNDS_CREATE_MODAL',
    };
};

export const openRoundsUpdate = () => {

    return {
        type: 'OPEN_ROUNDS_UPDATE_MODAL',
    };
};

export const closeRoundsUpdate = () => {

    return {
        type: 'CLOSE_ROUNDS_UPDATE_MODAL',
    };
};

export const openRoundDivisionsCreate = () => {

    return {
        type: 'OPEN_ROUND_DIVISIONS_CREATE_MODAL',
    };
};

export const closeRoundDivisionsCreate = () => {

    return {
        type: 'CLOSE_ROUND_DIVISIONS_CREATE_MODAL',
    };
};

export const openRoundDivisionsUpdate = () => {

    return {
        type: 'OPEN_ROUND_DIVISIONS_UPDATE_MODAL',
    };
};

export const closeRoundDivisionsUpdate = () => {

    return {
        type: 'CLOSE_ROUND_DIVISIONS_UPDATE_MODAL',
    };
};

export const openRoundMatchupsCreate = () => {

    return {
        type: 'OPEN_ROUND_MATCHUPS_CREATE_MODAL',
    };
};

export const closeRoundMatchupsCreate = () => {

    return {
        type: 'CLOSE_ROUND_MATCHUPS_CREATE_MODAL',
    };
};

export const openRoundMatchupsUpdate = () => {

    return {
        type: 'OPEN_ROUND_MATCHUPS_UPDATE_MODAL',
    };
};

export const closeRoundMatchupsUpdate = () => {

    return {
        type: 'CLOSE_ROUND_MATCHUPS_UPDATE_MODAL',
    };
};

export const openRoundDivisionTeams = () => {

    return {
        type: 'OPEN_ROUND_DIVISION_TEAMS_MODAL',
    };
};

export const closeRoundDivisionTeams = () => {

    return {
        type: 'CLOSE_ROUND_DIVISION_TEAMS_MODAL',
    };
};

export const resetModals = () => {

    return {
        type: 'RESET_MODALS',
    };
};