// the default list of players, which is empty
const playersDefaultState = {
    players: [],
    loading: true
};

export default (state = playersDefaultState, action) => {
    switch (action.type) {

        case 'SET_PLAYERS_LOADING':
            return {
                ...state,
                loading: true
            };

        case 'LOAD_PLAYERS':
            return {
                ...state,
                players: action.players,
                loading: false
            };

        default:
            return state;
    }
};