// the default list of divisions, which is empty
const divisionsDefaultState = {
    divisions: [],
    loading: true
};

export default (state = divisionsDefaultState, action) => {
    switch (action.type) {

        case 'SET_DIVISIONS_LOADING':
            return {
                ...state,
                loading: true
            };

        case 'LOAD_DIVISIONS':
            return {
                ...state,
                divisions: action.divisions,
                loading: false
            };

        default:
            return state;
    }
};