export const roundFormUpdate = ({ prop, value }) => {

    return {
        type: 'ROUND_FORM_UPDATE',
        field: { prop, value }
    };
};

export const roundFormError = (message) => {

    return {
        type: 'ROUND_FORM_ERROR',
        message
    };
};

export const roundFormSuccess = (message) => {

    return {
        type: 'ROUND_FORM_SUCCESS',
        message
    };
};

export const roundFormReset = () => {
    
    return {
        type: 'ROUND_FORM_RESET',
    };
}