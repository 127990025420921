import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { logout } from '../../actions/auth';
import { openLeftSidebar } from '../../actions/sidebar';

class Header extends React.Component {

    constructor(props) {
        super(props);

        this.onClickLogout = this.onClickLogout.bind(this);
        this.openLeftSidebar = this.openLeftSidebar.bind(this);
    }

    openLeftSidebar() {
        this.props.dispatch(openLeftSidebar());
    }

    onClickLogout() {
        this.props.dispatch(logout());
    }

    render() {

        return (
            <div>
                <AppBar position="static" className="header">
                    {(this.props.location.pathname !== "/login" && this.props.location.pathname !== "/login/" && this.props.location.pathname !== "/" && this.props.location.pathname !== "") && (
                        <Toolbar>
                            <IconButton className="menu-button" color="inherit" aria-label="Menu" onClick={this.openLeftSidebar}>
                                <MenuIcon />
                            </IconButton>
                            <Typography variant="h6" color="inherit" className="grow">
                                FunLeague
                            </Typography>
                            <Button color="inherit" onClick={this.onClickLogout}>Logout</Button>
                        </Toolbar>
                    )}
                    {(this.props.location.pathname === "/login" || this.props.location.pathname === "/login/" || this.props.location.pathname === "/" || this.props.location.pathname === "") && (
                        <Toolbar />
                    )}
                </AppBar>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        organization: 1
    };
};

export default withRouter(connect(mapStateToProps)(Header));