import React from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import Reorder, {
    reorder,
    reorderImmutable,
    reorderFromTo,
    reorderFromToImmutable
  } from 'react-reorder';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from '@material-ui/core/Icon';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

import RulesListItem from "./RulesListItem";
import GridItem from "./common/GridItem";
import GridContainer from "./common/GridContainer";
import Table from "./common/Table";
import Card from "./common/Card";
import CardHeader from "./common/CardHeader";
import CardBody from "./common/CardBody";
import verifyLogin from '../utils/verifyLogin';
import validateEmail from '../utils/validateEmail';
import { getLeagueRules, addRule, updateRule } from '../actions/rules';
import { ruleFormUpdate, ruleFormError, ruleFormReset } from '../actions/ruleForm';
import { openRulesCreate, closeRulesCreate, openRulesUpdate, closeRulesUpdate } from '../actions/modals';
import { setSelectedLeagueID } from '../actions/selectedLeague';


// import setAuthToken from '../utils/setAuthToken';

const styles = {
    cardCategoryWhite: {
      "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
      },
      "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
      }
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
      }
    }
};


class RulesIndexPage extends React.Component {

    constructor(props) {
        super(props);

        this.toggleCreateModal = this.toggleCreateModal.bind(this);
        this.toggleUpdateModal = this.toggleUpdateModal.bind(this);
        this.submitCreateRule = this.submitCreateRule.bind(this);
        this.submitUpdateRule = this.submitUpdateRule.bind(this);

        this.state = {
            selectedRule: {}
        };
    }

    onReorder (event, previousIndex, nextIndex, fromId, toId) {

        console.log(event);
        console.log(previousIndex);
        // this.setState({
        //   myList: reorder(this.state.myList, previousIndex, nextIndex);
        // });
    }

    toggleUpdateModal(isOpen, selectedRule) {
        if (isOpen) {
            this.setState(() => ({
                selectedRule
            }));
            this.props.dispatch(ruleFormReset());
            this.props.dispatch(ruleFormUpdate({ prop: 'title', value: selectedRule.title }));
            this.props.dispatch(ruleFormUpdate({ prop: 'rule', value: selectedRule.rule }));
            this.props.dispatch(openRulesUpdate());
        }
        else {
            this.props.dispatch(closeRulesUpdate());
        }
    }

    componentDidMount() {
        this.props.dispatch(setSelectedLeagueID(this.props.match.params.id));
        verifyLogin(this.props.history);

        // checkOrganization(this.props.match.params.id, this.props.organization, this.props.dispatch); // What is this? is it needed?
        this.props.dispatch(getLeagueRules(this.props.match.params.id));
    }

    componentDidUpdate() {
        verifyLogin(this.props.history);
    }

    toggleCreateModal(isOpen) {
        if (isOpen) {
            this.props.dispatch(ruleFormReset());
            this.props.dispatch(openRulesCreate());
        }
        else {
            this.props.dispatch(closeRulesCreate());
        }
    }

    submitCreateRule() {

        // perform some error checking
        if (this.props.ruleForm.title.trim() == "" && this.props.ruleForm.rule.trim() == "") {

            // required fields aren't present
            this.props.dispatch(ruleFormError("Please enter at least a rule title or rule content."));
        }
        else {

            // add the rule
            this.props.dispatch(addRule(this.props.match.params.id, this.props.ruleForm));
        }
    }

    submitUpdateRule() {

        // perform some error checking
        if (this.props.ruleForm.title.trim() == "" && this.props.ruleForm.rule.trim() == "") {

            // required fields aren't present
            this.props.dispatch(ruleFormError("Please enter at least a rule title or rule content."));
        }
        else {

            // add the committee member
            this.props.dispatch(updateRule(this.props.match.params.id, this.state.selectedRule.id, this.props.ruleForm));
        }
    }

    renderCreateModal() {
        const { classes } = this.props;

        return (
            <Modal
                aria-labelledby="rule-add"
                aria-describedby="rule-add"
                open={this.props.createModalOpen}
                onClose={() => this.toggleCreateModal(false)}
                className="modal_admin"
            >
                <div className="modal-member">
                    <Typography variant="h4" className="modal-title">
                        Add a Rule
                    </Typography>
                    <Typography className="modal-description">
                        Please add the rule title and rule content, than click 'Add Rule'. If you want this to be a category for rules, don't include the rule content.
                    </Typography>

                    { this.props.ruleForm.error && (
                        <Typography className="modal-error">
                            { this.props.ruleForm.error }
                        </Typography>
                    )}

                    <TextField
                        id="create-rule-title"
                        label="Title"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.ruleForm.title}
                        onChange={(e) => { this.props.dispatch(ruleFormUpdate({ prop: 'title', value: e.target.value })); }}
                        margin="normal"
                    />

                    <TextField
                        id="create-rule-content"
                        label="Rule"
                        className="modal-input"
                        fullWidth={true}
                        multiline={true}
                        value={this.props.ruleForm.rule}
                        onChange={(e) => { this.props.dispatch(ruleFormUpdate({ prop: 'rule', value: e.target.value })); }}
                        margin="normal"
                    />

                    <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.submitCreateRule}>
                        Add Rule
                    </Button>
                </div>
            </Modal>
        )
    }

    renderUpdateModal() {
        return (
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={this.props.updateModalOpen}
                onClose={() => this.toggleUpdateModal(false)}
                className="modal_admin"
            >
                <div className="modal-member">
                    <Typography variant="h4" className="modal-title">
                        Update Rule
                    </Typography>
                    <Typography className="modal-description">
                        Please update your rule title and description, and click the update button to continue.
                    </Typography>

                    { this.props.ruleForm.error && (
                        <Typography className="modal-error">
                            { this.props.ruleForm.error }
                        </Typography>
                    )}

                    { this.props.ruleForm.message && (
                        <Typography className="modal-success">
                            { this.props.ruleForm.message }
                        </Typography>
                    )}

                    <TextField
                        id="create-rule-title"
                        label="Title"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.ruleForm.title}
                        onChange={(e) => { this.props.dispatch(ruleFormUpdate({ prop: 'title', value: e.target.value })); }}
                        margin="normal"
                    />

                    <TextField
                        id="create-rule-content"
                        label="Rule"
                        className="modal-input"
                        fullWidth={true}
                        multiline={true}
                        value={this.props.ruleForm.rule}
                        onChange={(e) => { this.props.dispatch(ruleFormUpdate({ prop: 'rule', value: e.target.value })); }}
                        margin="normal"
                    />

                    <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.submitUpdateRule}>
                        Update Rule
                    </Button>
                </div>
            </Modal>
        )
    }

    renderRules() {
        const { classes } = this.props;
        return (
            <GridContainer className="container-full">
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="primary" className="card-header">
                            <h4 className={classes.cardTitleWhite}>{this.props.league.name}</h4>
                            <p className={classes.cardCategoryWhite}>
                            Rules Management
                            </p>
                        </CardHeader>

                        {(this.props.rules.length <= 0) ? (
                            <CardBody>
                                <p>You don't have any rules set up yet. You can add a new rule by clicking on the Add button below.</p>
                            </CardBody>
                        ) : (
                            <CardBody>
                                <p>You can update your rules below, or add a new rule by clicking on the Add button below.</p>
                            </CardBody>
                        )}
                    </Card>
                    
                    <Reorder
                        reorderId="rules-list-main" // Unique ID that is used internally to track this list (required)
                        // reorderGroup="reorder-group" // A group ID that allows items to be dragged between lists of the same group (optional)
                        // getRef={this.storeRef.bind(this)} // Function that is passed a reference to the root node when mounted (optional)
                        // component="ul" // Tag name or Component to be used for the wrapping element (optional), defaults to 'div'
                        placeholderClassName="placeholder" // Class name to be applied to placeholder elements (optional), defaults to 'placeholder'
                        draggedClassName="dragged" // Class name to be applied to dragged elements (optional), defaults to 'dragged'
                        // lock="horizontal" // Lock the dragging direction (optional): vertical, horizontal (do not use with groups)
                        // holdTime={500} // Default hold time before dragging begins (mouse & touch) (optional), defaults to 0
                        // touchHoldTime={500} // Hold time before dragging begins on touch devices (optional), defaults to holdTime
                        // mouseHoldTime={200} // Hold time before dragging begins with mouse (optional), defaults to holdTime
                        onReorder={this.onReorder.bind(this)} // Callback when an item is dropped (you will need this to update your state)
                        autoScroll={true} // Enable auto-scrolling when the pointer is close to the edge of the Reorder component (optional), defaults to true
                        disabled={false} // Disable reordering (optional), defaults to false
                        disableContextMenus={true} // Disable context menus when holding on touch devices (optional), defaults to true
                        placeholder={
                            <div className="custom-placeholder" /> // Custom placeholder element (optional), defaults to clone of dragged element
                        }
                    >
                        {this.props.rules.length > 0 && this.props.rules.map((rule) => <RulesListItem key={rule.id} match={this.props.match} rule={rule} toggleUpdateModal={this.toggleUpdateModal} />)}    
                    </Reorder>
                </GridItem>
            </GridContainer>
        );
    }
    
    render() {
        console.log(this.props.league)
        return (
            <div>
                { this.renderRules() }

                <Fab className="btn-fab btn-add" onClick={() => this.toggleCreateModal(true)}>
                    <AddIcon />
                </Fab>

                {this.renderCreateModal()}
                {this.renderUpdateModal()}
            </div>
        );
    }
};

const mapStateToProps = (state) => {

    return {
        isAuthenticated: state.auth.isAuthenticated,
        loading: state.members.loading,
        rules: state.rules.rules,
        ruleForm: state.ruleForm,
        createModalOpen: state.modals.rulesCreate,
        updateModalOpen: state.modals.rulesUpdate,
        league: state.selectedLeague.league
    };
};

export default connect(mapStateToProps)(withStyles(styles)(RulesIndexPage));
