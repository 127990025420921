import { getInputGameTime } from '../utils/dateUtil';
import moment from 'moment';

export const gameFormUpdate = ({ prop, value }) => {

    return {
        type: 'GAME_FORM_UPDATE',
        field: { prop, value }
    };
};

export const gameFormLoadDate = (theGameTime) => {

    let game_time = new Date(theGameTime);

    return {
        type: 'GAME_FORM_UPDATE',
        field: { prop: 'game_time', value: game_time }
    };
};

export const gameFormError = (message) => {

    return {
        type: 'GAME_FORM_ERROR',
        message
    };
};

export const gameFormSuccess = (message) => {

    return {
        type: 'GAME_FORM_SUCCESS',
        message
    };
};

export const gameFormReset = () => {
    
    return {
        type: 'GAME_FORM_RESET',
    };
}