import React from "react";
import { connect } from 'react-redux';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from '@material-ui/core/Icon';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import GridItem from "./common/GridItem";
import GridContainer from "./common/GridContainer";
import Table from "./common/Table";
import Card from "./common/Card";
import CardHeader from "./common/CardHeader";
import CardBody from "./common/CardBody";
import verifyLogin from '../utils/verifyLogin';
import { getTeamPlayers, addPlayers, updatePlayer, removePlayer } from '../actions/players';
import { playerFormUpdate, playerFormError, playerFormReset } from '../actions/playerForm';
import { openPlayersCreate, closePlayersCreate, openPlayersUpdate, closePlayersUpdate } from '../actions/modals';
import { setSelectedLeagueID } from '../actions/selectedLeague';


// import setAuthToken from '../utils/setAuthToken';

const styles = {
    cardCategoryWhite: {
      "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
      },
      "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
      }
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
      }
    }
};


class TeamPlayersPage extends React.Component {

    constructor(props) {
        super(props);

        this.toggleCreateModal = this.toggleCreateModal.bind(this);
        this.toggleUpdateModal = this.toggleUpdateModal.bind(this);
        this.submitCreatePlayers = this.submitCreatePlayers.bind(this);
        this.submitUpdatePlayer = this.submitUpdatePlayer.bind(this);

        this.state = {
            selectedPlayer: {}
        };
    }

    componentDidMount() {
        this.props.dispatch(setSelectedLeagueID(this.props.match.params.id));
        verifyLogin(this.props.history);

        this.props.dispatch(getTeamPlayers(this.props.match.params.id, this.props.match.params.tid));
    }

    componentDidUpdate() {
        verifyLogin(this.props.history);
    }

    toggleUpdateModal(isOpen, selectedPlayer) {
        console.log(selectedPlayer);
        if (isOpen) {
            this.setState(() => ({
                selectedPlayer
            }));
            this.props.dispatch(playerFormReset());
            this.props.dispatch(playerFormUpdate({ prop: 'name', value: selectedPlayer.name }));
            this.props.dispatch(playerFormUpdate({ prop: 'position', value: selectedPlayer.position }));
            this.props.dispatch(openPlayersUpdate());
        }
        else {
            this.props.dispatch(closePlayersUpdate());
        }
    }

    toggleCreateModal(isOpen) {
        if (isOpen) {
            this.props.dispatch(openPlayersCreate());
        }
        else {
            this.props.dispatch(closePlayersCreate());
        }
    }

    submitCreatePlayers() {

        // perform some error checking
        if (this.props.playerForm.players.trim() == "") {

            // required players aren't present
            this.props.dispatch(playerFormError("Please enter at least one player before submitting."));
        }
        else {

            // add the players
            this.props.dispatch(addPlayers(this.props.match.params.id, this.props.match.params.tid, this.props.playerForm));
        }
    }

    submitUpdatePlayer() {

        // perform some error checking
        if (this.props.playerForm.name.trim() == "") {

            // required player info isn't present
            this.props.dispatch(playerFormError("Please enter a player name."));
        }
        else {

            // add the committee member
            this.props.dispatch(updatePlayer(this.props.match.params.id, this.props.match.params.tid, this.state.selectedPlayer.id, this.props.playerForm));
        }
    }

    removePlayer({ id }) {
        if (confirm("Are you sure you want to remove this player? This cannot be undone")) {
            this.props.dispatch(removePlayer(id, this.props.match.params.id, this.props.match.params.tid));
        };
    }

    renderCreateModal() {
        const { classes } = this.props;

        return (
            <Modal
                aria-labelledby="players-add"
                aria-describedby="players-add"
                open={this.props.createModalOpen}
                onClose={() => this.toggleCreateModal(false)}
                className="modal_admin"
            >
                <div className="modal-member">
                    <Typography variant="h4" className="modal-title">
                        Add Players
                    </Typography>
                    <Typography className="modal-description">
                        Please add each player on a separate line, than click 'Add Players'.
                    </Typography>

                    { this.props.playerForm.error && (
                        <Typography className="modal-error">
                            { this.props.playerForm.error }
                        </Typography>
                    )}

                    <TextField
                        id="create-player-names"
                        label="Players"
                        multiline
                        rows="4"
                        rowsMax="12"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.playerForm.players}
                        onChange={(e) => { this.props.dispatch(playerFormUpdate({ prop: 'players', value: e.target.value })); }}
                        margin="normal"
                    />

                    <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.submitCreatePlayers}>
                        Add Players
                    </Button>
                </div>
            </Modal>
        )
    }

    renderUpdateModal() {
        return (
            <Modal
                aria-labelledby="player-update"
                aria-describedby="player-update"
                open={this.props.updateModalOpen}
                onClose={() => this.toggleUpdateModal(false)}
                className="modal_admin"
            >
                <div className="modal-member">
                    <Typography variant="h4" className="modal-title">
                        Update {this.state.selectedPlayer.name}
                    </Typography>
                    <Typography className="modal-description">
                        Please update the player's information, and click the update button to continue.
                    </Typography>

                    { this.props.playerForm.error && (
                        <Typography className="modal-error">
                            { this.props.playerForm.error }
                        </Typography>
                    )}

                    { this.props.playerForm.message && (
                        <Typography className="modal-success">
                            { this.props.playerForm.message }
                        </Typography>
                    )}

                    <TextField
                        id="update-player-name"
                        label="Name"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.playerForm.name}
                        onChange={(e) => { this.props.dispatch(playerFormUpdate({ prop: 'name', value: e.target.value })); }}
                        margin="normal"
                    />

                    <TextField
                        id="update-player-position"
                        label="Position"
                        className="modal-input"
                        fullWidth={true}
                        multiline={true}
                        value={this.props.playerForm.position}
                        onChange={(e) => { this.props.dispatch(playerFormUpdate({ prop: 'position', value: e.target.value })); }}
                        margin="normal"
                    />

                    <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.submitUpdatePlayer}>
                        Update Player
                    </Button>
                </div>
            </Modal>
        )
    }

    renderTeamPlayers() {
        const { classes } = this.props;
        console.log(this.props);

        return (
            <GridContainer className="container-full">
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="primary" className="card-header">
                            <p className={classes.cardCategoryWhite}>
                            {this.props.team.name} Players
                            </p>
                        </CardHeader>
                        { (this.props.players.length <= 0) ? (
                            <CardBody>
                                <p>No players on the team yet!</p>
                            </CardBody>
                        ) : (
                            <CardBody>
                                <Table
                                tableHeaderColor="primary"
                                tableHead={["Name", "Position", "Actions"]}
                                tableData={this.props.players.map((thePlayer) => [
                                    thePlayer.name, 
                                    thePlayer.position, 
                                    (
                                        <div>
                                            <Icon className="fa fa-pen manager-table-icon manager-table-edit" onClick={() => this.toggleUpdateModal(true, thePlayer)} />
                                            <Icon className="fa fa-trash manager-table-remove" onClick={() => this.removePlayer(thePlayer)} />
                                        </div>
                                    )
                                ])}
                                />
                            </CardBody>
                        )}
                    </Card>
                </GridItem>
            </GridContainer>
        );
    }
    
    render() {
        return (
            <div>
                { this.renderTeamPlayers() }

                <Fab className="btn-fab btn-add" color="primary" onClick={() => this.toggleCreateModal(true)}>
                    <AddIcon />
                </Fab>

                {this.renderCreateModal()}
                {this.renderUpdateModal()}
            </div>
        );
    }
};

const mapStateToProps = (state, props) => {

    return {
        isAuthenticated: state.auth.isAuthenticated,
        loading: state.members.loading,
        players: state.players.players,
        playerForm: state.playerForm,
        team: state.teams.teams.find((team) => { return team.id == props.match.params.tid;}) || [],
        createModalOpen: state.modals.playersCreate,
        updateModalOpen: state.modals.playersUpdate,
    };
};

export default connect(mapStateToProps)(withStyles(styles)(TeamPlayersPage));
