import axios from 'axios';
import { API_URL } from '../config/constants';
import removeAuthToken from '../utils/removeAuthToken';
import { roundFormReset, roundFormError, roundFormSuccess } from './roundForm';
import { setSelectedLeague } from './selectedLeague';
import { closeRoundsCreate, closeRoundDivisionsCreate, closeRoundMatchupsCreate, closeGamesCreate } from './modals';
import { getFormattedTime, getGameTime } from '../utils/dateUtil';
import { divisionFormReset, divisionFormError, divisionFormSuccess } from './divisionForm';
import { gameFormReset, gameFormError, gameFormSuccess } from './gameForm';
import { matchupFormReset, matchupFormError, matchupFormSuccess } from './matchupForm';
import { loadTeams } from './teams';

export const loadPlayoffs = (rounds) => ({
    type: 'LOAD_PLAYOFFS',
    rounds
});

export const loadPlayoffGames = (games) => ({
    type: 'LOAD_PLAYOFF_GAMES',
    games
});

export const loadSelectedDivisionTeams = (teams) => ({
    type: 'LOAD_SELECTED_DIVISION_TEAMS',
    teams
});

export const setPlayoffsLoading = () => ({
    type: 'SET_PLAYOFFS_LOADING'
});

export const getPlayoffRounds = (id) => {

    return (dispatch) => {

        dispatch(setPlayoffsLoading());
        return axios.get(API_URL+'/api/playoffs/'+id)
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(loadPlayoffs(res.data.playoffRounds));
                    if (res.data.teams) {
                        dispatch(loadTeams(res.data.teams));
                    }
                    dispatch(setSelectedLeague(res.data.league));
                }
                else {
                    console.log(res.data);
                    console.log("Could not get playoffs!");
                }
            })
            .catch(function(err) {
                
                // token is likely invalid; remove it and prompt the user to login again
                console.log(err);
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const addRound = (id, { name, type, start_date, end_date } = {}) => {
    
    return (dispatch) => {

        dispatch(setPlayoffsLoading());
        return axios.post(API_URL+'/api/playoffs/'+id+'/create', { name, type, start_date: getFormattedTime(start_date, true), end_date: getFormattedTime(end_date, true) })
            .then(function(res) {
                if (res.data.status == 'success') {
                    if (res.data.teams) {
                        dispatch(loadTeams(res.data.teams));
                    }
                    dispatch(loadPlayoffs(res.data.playoffRounds));
                    dispatch(roundFormReset());
                    dispatch(closeRoundsCreate());
                }
                else {
                    dispatch(roundFormError(res.data.message));
                }
            })
            .catch(function(err) {
                
                // token is likely invalid; remove it and prompt the user to login again
                console.log(err);
                dispatch(roundFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const updateRound = (id, rid, { name, type, start_date, end_date } = {}) => {
    
    return (dispatch) => {

        dispatch(setPlayoffsLoading());
        return axios.put(API_URL+'/api/playoffs/'+id+'/'+rid, { name, type, start_date: getFormattedTime(start_date, true), end_date: getFormattedTime(end_date, true) })
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(loadPlayoffs(res.data.playoffRounds));
                    if (res.data.teams) {
                        dispatch(loadTeams(res.data.teams));
                    }
                    dispatch(roundFormSuccess('Playoff Round Updated!'));
                }
                else {
                    dispatch(roundFormError(res.data.message));
                    console.log(res.data);
                    console.log("Could not add your playoff round!");
                }
            })
            .catch(function(err) {

                // token is likely invalid; remove it and prompt the user to login again
                dispatch(roundFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const removeRound = (rid, id) => {

    return (dispatch) => {

        dispatch(setPlayoffsLoading());
        return axios.delete(API_URL+'/api/playoffs/'+id+'/'+rid, {})
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(loadPlayoffs(res.data.playoffRounds));
                    if (res.data.teams) {
                        dispatch(loadTeams(res.data.teams));
                    }
                }
                else {
                    // dispatch(roundFormError(res.data.message));
                    console.log(res.data);
                    console.log("Could not remove your playoff round!");
                }
            })
            .catch(function(err) {

                // token is likely invalid; remove it and prompt the user to login again
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const addPlayoffDivision = (id, { name, round_id } = {}) => {
    
    return (dispatch) => {

        dispatch(setPlayoffsLoading());
        return axios.post(API_URL+'/api/playoffs/'+id+'/divisions/create', { name, round_id })
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(loadPlayoffs(res.data.playoffRounds));
                    if (res.data.teams) {
                        dispatch(loadTeams(res.data.teams));
                    }
                    dispatch(divisionFormReset());
                    dispatch(closeRoundDivisionsCreate());
                }
                else {
                    dispatch(divisionFormError(res.data.message));
                }
            })
            .catch(function(err) {
                
                // token is likely invalid; remove it and prompt the user to login again
                console.log(err);
                dispatch(divisionFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const updatePlayoffDivision = (id, did, { name } = {}) => {
    
    return (dispatch) => {

        dispatch(setPlayoffsLoading());
        return axios.put(API_URL+'/api/playoffs/'+id+'/divisions/'+did, { name })
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(loadPlayoffs(res.data.playoffRounds));
                    if (res.data.teams) {
                        dispatch(loadTeams(res.data.teams));
                    }
                    dispatch(divisionFormSuccess('division Updated!'));
                }
                else {
                    dispatch(divisionFormError(res.data.message));
                    console.log(res.data);
                    console.log("Could not update your division!");
                }
            })
            .catch(function(err) {

                // token is likely invalid; remove it and prompt the user to login again
                dispatch(divisionFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const removePlayoffDivision = (did, id) => {

    return (dispatch) => {

        dispatch(setPlayoffsLoading());
        return axios.delete(API_URL+'/api/playoffs/'+id+'/divisions/'+did, {})
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(loadPlayoffs(res.data.playoffRounds));
                    if (res.data.teams) {
                        dispatch(loadTeams(res.data.teams));
                    }
                }
                else {
                    // dispatch(divisionFormError(res.data.message));
                    console.log(res.data);
                    console.log("Could not remove your division!");
                }
            })
            .catch(function(err) {

                // token is likely invalid; remove it and prompt the user to login again
                console.log(err);
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
}

export const addPlayoffMatchup = (id, { name, round_id, home_team_id, away_team_id } = {}) => {
    
    return (dispatch) => {

        dispatch(setPlayoffsLoading());
        return axios.post(API_URL+'/api/playoffs/'+id+'/matchups/create', { name, round_id, home_team_id, away_team_id })
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(loadPlayoffs(res.data.playoffRounds));
                    if (res.data.teams) {
                        dispatch(loadTeams(res.data.teams));
                    }
                    dispatch(matchupFormReset());
                    dispatch(closeRoundMatchupsCreate());
                }
                else {
                    dispatch(matchupFormError(res.data.message));
                }
            })
            .catch(function(err) {
                
                // token is likely invalid; remove it and prompt the user to login again
                console.log(err);
                dispatch(matchupFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const updatePlayoffMatchup = (id, did, { name, home_team_id, away_team_id } = {}) => {
    
    return (dispatch) => {

        dispatch(setPlayoffsLoading());
        return axios.put(API_URL+'/api/playoffs/'+id+'/matchups/'+did, { name, home_team_id, away_team_id })
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(loadPlayoffs(res.data.playoffRounds));
                    if (res.data.teams) {
                        dispatch(loadTeams(res.data.teams));
                    }
                    dispatch(matchupFormSuccess('Matchup Updated!'));
                }
                else {
                    dispatch(matchupFormError(res.data.message));
                    console.log(res.data);
                    console.log("Could not update your matchup!");
                }
            })
            .catch(function(err) {

                // token is likely invalid; remove it and prompt the user to login again
                dispatch(matchupFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const removePlayoffMatchup = (did, id) => {

    return (dispatch) => {

        dispatch(setPlayoffsLoading());
        return axios.delete(API_URL+'/api/playoffs/'+id+'/matchups/'+did, {})
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(loadPlayoffs(res.data.playoffRounds));
                    if (res.data.teams) {
                        dispatch(loadTeams(res.data.teams));
                    }
                }
                else {
                    // dispatch(matchupFormError(res.data.message));
                    console.log(res.data);
                    console.log("Could not remove your matchup!");
                }
            })
            .catch(function(err) {

                // token is likely invalid; remove it and prompt the user to login again
                console.log(err);
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
}

export const addPlayoffDivisionTeam = (id, division_id, team_id) => {
    
    return (dispatch) => {

        dispatch(setPlayoffsLoading());
        return axios.post(API_URL+'/api/playoffs/'+id+'/divisions/addteam', { division_id, team_id })
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(loadPlayoffs(res.data.playoffRounds));
                    dispatch(loadSelectedDivisionTeams(res.data.updatedTeams));
                    if (res.data.teams) {
                        dispatch(loadTeams(res.data.teams));
                    }
                    dispatch(matchupFormReset());
                    dispatch(closeRoundMatchupsCreate());
                }
                else {
                    dispatch(matchupFormError(res.data.message));
                }
            })
            .catch(function(err) {
                
                // token is likely invalid; remove it and prompt the user to login again
                console.log(err);
                dispatch(matchupFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const removePlayoffDivisionTeam = (id, division_id, team_id) => {
    
    return (dispatch) => {

        dispatch(setPlayoffsLoading());
        return axios.post(API_URL+'/api/playoffs/'+id+'/divisions/removeteam', { division_id, team_id })
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(loadPlayoffs(res.data.playoffRounds));
                    dispatch(loadSelectedDivisionTeams(res.data.updatedTeams));
                    if (res.data.teams) {
                        dispatch(loadTeams(res.data.teams));
                    }
                    dispatch(matchupFormReset());
                    dispatch(closeRoundMatchupsCreate());
                }
                else {
                    dispatch(matchupFormError(res.data.message));
                }
            })
            .catch(function(err) {
                
                // token is likely invalid; remove it and prompt the user to login again
                console.log(err);
                dispatch(matchupFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const getPlayoffGames = (id) => {

    return (dispatch) => {

        dispatch(setPlayoffsLoading());
        return axios.get(API_URL+'/api/playoffs/schedule/'+id)
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(loadPlayoffGames(res.data.games));
                    dispatch(setSelectedLeague(res.data.league));
                }
                else {
                    console.log(res.data);
                    console.log("Could not get games!");
                }
            })
            .catch(function(err) {
                
                // token is likely invalid; remove it and prompt the user to login again
                console.log(err);
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};


export const addPlayoffGames = (id, game_date, { games } = {}) => {
    
    return (dispatch) => {

        const paramGames = games.map((theGame) => {
            return {
                ...theGame,
                game_time: getGameTime(game_date, theGame.game_time)
            };
        });

        dispatch(setPlayoffsLoading());
        return axios.post(API_URL+'/api/playoffs/schedule/'+id+'/create', { games: paramGames })
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(loadPlayoffGames(res.data.games));
                    dispatch(gameFormReset());
                    dispatch(closeGamesCreate());
                }
                else {
                    dispatch(gameFormError(res.data.message));
                }
            })
            .catch(function(err) {
                
                // token is likely invalid; remove it and prompt the user to login again
                console.log(err);
                dispatch(gameFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const updatePlayoffGame = (id, gid, { away_team_id, home_team_id, field_id, game_time } = {}) => {
    
    return (dispatch) => {

        const theGameTime = getFormattedTime(game_time);

        dispatch(setPlayoffsLoading());
        return axios.put(API_URL+'/api/playoffs/schedule/'+id+'/'+gid, { away_team_id, home_team_id, field_id, game_time: theGameTime })
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(loadPlayoffGames(res.data.games));
                    dispatch(gameFormSuccess('game Updated!'));
                }
                else {
                    dispatch(gameFormError(res.data.message));
                    console.log(res.data);
                    console.log("Could not add your game!");
                }
            })
            .catch(function(err) {

                // token is likely invalid; remove it and prompt the user to login again
                dispatch(gameFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const updatePlayoffGameScore = (id, gid, { away_score, home_score, is_away_forfeit, is_home_forfeit } = {}) => {
    
    return (dispatch) => {

        dispatch(setPlayoffsLoading());
        return axios.put(API_URL+'/api/playoffs/scores/'+id+'/'+gid, { away_score, home_score, is_away_forfeit, is_home_forfeit })
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(loadPlayoffGames(res.data.games));
                    dispatch(gameFormSuccess('Score Updated!'));
                }
                else {
                    dispatch(gameFormError(res.data.message));
                    console.log(res.data);
                    console.log("Could not update the game score.");
                }
            })
            .catch(function(err) {

                // token is likely invalid; remove it and prompt the user to login again
                dispatch(gameFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const removePlayoffGame = (gid, id) => {

    return (dispatch) => {

        dispatch(setPlayoffsLoading());
        return axios.delete(API_URL+'/api/playoffs/schedule/'+id+'/'+gid, {})
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(loadPlayoffGames(res.data.games));
                }
                else {
                    // dispatch(gameFormError(res.data.message));
                    console.log(res.data);
                    console.log("Could not remove your game!");
                }
            })
            .catch(function(err) {

                // token is likely invalid; remove it and prompt the user to login again
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
}