const playerFormDefaultState = {
    name: '',
    team_id: 0,
    players: '',
    position: '',
    error: '',
};

export default (state = playerFormDefaultState, action) => {
    
    switch (action.type) {

        case 'PLAYER_FORM_UPDATE':
            return {
                ...state,
                [action.field.prop]: action.field.value
            };

        case 'PLAYER_FORM_ERROR':
            return {
                ...state,
                message: '',
                error: action.message
            }

        case 'PLAYER_FORM_SUCCESS':
            return {
                ...state,
                error: '',
                message: action.message
            }

        case 'PLAYER_FORM_RESET':
            return playerFormDefaultState;

        default:
            return state;
    }
};