// Get games for the day

export default (games, game_date) => {

    return games.filter((theGame) => {     

        const gameTestDate = new Date(theGame.game_time);
        return (game_date.getFullYear() == gameTestDate.getFullYear() && game_date.getMonth() == gameTestDate.getMonth() && game_date.getDate() == gameTestDate.getDate());

    }).sort((a, b) => {

        return a.game_time > b.game_time ? 1 : -1;
    });
};