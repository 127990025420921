import axios from 'axios';
import { API_URL } from '../config/constants';
import removeAuthToken from '../utils/removeAuthToken';
import { teamFormReset, teamFormError, teamFormSuccess } from './teamForm';
import { loadDivisions } from './divisions';
import { closeTeamsCreate } from './modals';
import { setSelectedLeague } from './selectedLeague';

export const loadTeams = (teams) => ({
    type: 'LOAD_TEAMS',
    teams
});

export const setTeamsLoading = () => ({
    type: 'SET_TEAMS_LOADING'
});

export const getLeagueTeams = (id) => {

    return (dispatch) => {

        dispatch(setTeamsLoading());
        return axios.get(API_URL+'/api/teams/'+id)
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(loadDivisions(res.data.divisions));
                    dispatch(loadTeams(res.data.teams));
                    dispatch(setSelectedLeague(res.data.league));
                }
                else {
                    console.log(res.data);
                    console.log("Could not get teams!");
                }
            })
            .catch(function(err) {
                
                // token is likely invalid; remove it and prompt the user to login again
                console.log(err);
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const addTeams = (id, { teams, division_id = 0 } = {}) => {
    
    return (dispatch) => {

        dispatch(setTeamsLoading());
        return axios.post(API_URL+'/api/teams/'+id+'/create', { teams, division_id })
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(loadTeams(res.data.teams));
                    dispatch(teamFormReset());
                    dispatch(closeTeamsCreate());
                }
                else {
                    dispatch(teamFormError(res.data.message));
                }
            })
            .catch(function(err) {
                
                // token is likely invalid; remove it and prompt the user to login again
                console.log(err);
                dispatch(teamFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const updateTeam = (id, tid, { name, division_id, manager_name, manager_phone } = {}) => {
    
    return (dispatch) => {

        dispatch(setTeamsLoading());
        return axios.put(API_URL+'/api/teams/'+id+'/'+tid, { name, division_id, manager_name, manager_phone })
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(loadTeams(res.data.teams));
                    dispatch(teamFormSuccess('Team Updated!'));
                }
                else {
                    dispatch(teamFormError(res.data.message));
                    console.log(res.data);
                    console.log("Could not update your team!");
                }
            })
            .catch(function(err) {

                // token is likely invalid; remove it and prompt the user to login again
                dispatch(teamFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const removeTeam = (tid, id) => {

    return (dispatch) => {

        dispatch(setTeamsLoading());
        return axios.delete(API_URL+'/api/teams/'+id+'/'+tid, {})
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(loadTeams(res.data.teams));
                }
                else {
                    // dispatch(teamFormError(res.data.message));
                    console.log(res.data);
                    console.log("Could not remove your team!");
                }
            })
            .catch(function(err) {

                // token is likely invalid; remove it and prompt the user to login again
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
}