export const matchupFormUpdate = ({ prop, value }) => {

    return {
        type: 'MATCHUP_FORM_UPDATE',
        field: { prop, value }
    };
};

export const matchupFormError = (message) => {

    return {
        type: 'MATCHUP_FORM_ERROR',
        message
    };
};

export const matchupFormSuccess = (message) => {

    return {
        type: 'MATCHUP_FORM_SUCCESS',
        message
    };
};

export const matchupFormReset = () => {
    
    return {
        type: 'MATCHUP_FORM_RESET',
    };
}