// the default list of rules, which is empty
const teamsDefaultState = {
    teams: [],
    loading: true
};

export default (state = teamsDefaultState, action) => {
    switch (action.type) {

        case 'SET_TEAMS_LOADING':
            return {
                ...state,
                loading: true
            };

        case 'LOAD_TEAMS':
            return {
                ...state,
                teams: action.teams,
                loading: false
            };

        default:
            return state;
    }
};