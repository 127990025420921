const selectedLeagueDefaultState = {
    id: 0,
    sitename: '',
    league: {}
};

export default (state = selectedLeagueDefaultState, action) => {
    switch (action.type) {

        case 'SET_SELECTED_LEAGUE_ID':
            return {
                ...state,
                id: action.id
            };

        case 'SET_SELECTED_LEAGUE':
            return {
                ...state,
                league: action.league
            };

        default:
            return state;
    }
};