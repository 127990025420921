export const fieldFormUpdate = ({ prop, value }) => {

    return {
        type: 'FIELD_FORM_UPDATE',
        field: { prop, value }
    };
};

export const fieldFormError = (message) => {

    return {
        type: 'FIELD_FORM_ERROR',
        message
    };
};

export const fieldFormSuccess = (message) => {

    return {
        type: 'FIELD_FORM_SUCCESS',
        message
    };
};

export const fieldFormReset = () => {
    
    return {
        type: 'FIELD_FORM_RESET',
    };
}