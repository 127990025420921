export const playerFormUpdate = ({ prop, value }) => {

    return {
        type: 'PLAYER_FORM_UPDATE',
        field: { prop, value }
    };
};

export const playerFormError = (message) => {

    return {
        type: 'PLAYER_FORM_ERROR',
        message
    };
};

export const playerFormSuccess = (message) => {

    return {
        type: 'PLAYER_FORM_SUCCESS',
        message
    };
};

export const playerFormReset = () => {
    
    return {
        type: 'PLAYER_FORM_RESET',
    };
}