const memberFormDefaultState = {
    email: '',
    role: '',
    message: '',
    error: '',
};

export default (state = memberFormDefaultState, action) => {
    
    switch (action.type) {

        case 'MEMBER_FORM_UPDATE':
            return {
                ...state,
                [action.field.prop]: action.field.value
            };

        case 'MEMBER_FORM_ERROR':
            return {
                ...state,
                message: '',
                error: action.message
            }

        case 'MEMBER_FORM_SUCCESS':
            return {
                ...state,
                error: '',
                message: action.message
            }

        case 'MEMBER_FORM_RESET':
            return memberFormDefaultState;

        default:
            return state;
    }
};