import axios from 'axios';
import { API_URL } from '../config/constants';
import removeAuthToken from '../utils/removeAuthToken';
import { fieldFormReset, fieldFormError, fieldFormSuccess } from './fieldForm';
import { closeFieldsCreate } from './modals';
import { setSelectedLeague } from './selectedLeague';

export const setFields = (fields) => ({
    type: 'SET_FIELDS',
    fields
});

export const setFieldsLoading = () => ({
    type: 'SET_FIELDS_LOADING'
});

export const getLeagueFields = (id) => {

    return (dispatch) => {

        dispatch(setFieldsLoading());
        return axios.get(API_URL+'/api/fields/'+id)
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(setFields(res.data.fields));
                    dispatch(setSelectedLeague(res.data.league));
                }
                else {
                    console.log(res.data);
                    console.log("Could not get fields!");
                }
            })
            .catch(function(err) {
                
                // token is likely invalid; remove it and prompt the user to login again
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const addField = (id, { name, description, field } = {}) => {
    
    return (dispatch) => {

        dispatch(setFieldsLoading());
        return axios.post(API_URL+'/api/fields/'+id+'/create', { name, description })
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(setFields(res.data.fields));
                    dispatch(fieldFormReset());
                    dispatch(closeFieldsCreate());
                }
                else {
                    dispatch(fieldFormError(res.data.message));
                }
            })
            .catch(function(err) {
                
                // token is likely invalid; remove it and prompt the user to login again
                console.log(err);
                dispatch(fieldFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const updateField = (id, fid, { name, description } = {}) => {
    
    return (dispatch) => {

        dispatch(setFieldsLoading());
        return axios.put(API_URL+'/api/fields/'+id+'/'+fid, { name, description })
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(setFields(res.data.fields));
                    dispatch(fieldFormSuccess('Field Updated!'));
                }
                else {
                    dispatch(fieldFormError(res.data.message));
                    console.log(res.data);
                    console.log("Could not add your field!");
                }
            })
            .catch(function(err) {

                // token is likely invalid; remove it and prompt the user to login again
                dispatch(fieldFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const removeField = (fid, id) => {

    return (dispatch) => {

        dispatch(setFieldsLoading());
        return axios.delete(API_URL+'/api/fields/'+id+'/'+fid, {})
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(setFields(res.data.fields));
                }
                else {
                    // dispatch(fieldFormError(res.data.message));
                    console.log(res.data);
                    console.log("Could not remove your field!");
                }
            })
            .catch(function(err) {

                // token is likely invalid; remove it and prompt the user to login again
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
}