import axios from 'axios';
import { API_URL } from '../config/constants';
import removeAuthToken from '../utils/removeAuthToken';
import { ruleFormReset, ruleFormError, ruleFormSuccess } from './ruleForm';
import { closeRulesCreate } from './modals';
import { setSelectedLeague } from './selectedLeague';

export const setRules = (rules) => ({
    type: 'SET_RULES',
    rules
});

export const setRulesLoading = () => ({
    type: 'SET_RULES_LOADING'
});

export const getLeagueRules = (id) => {

    return (dispatch) => {

        dispatch(setRulesLoading());
        return axios.get(API_URL+'/api/rules/'+id)
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(setRules(res.data.rules));
                    dispatch(setSelectedLeague(res.data.league));
                }
                else {
                    console.log(res.data);
                    console.log("Could not get rules!");
                }
            })
            .catch(function(err) {
                
                // token is likely invalid; remove it and prompt the user to login again
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const addRule = (id, { title, rule, parent_rule_id = 0 } = {}) => {
    
    return (dispatch) => {

        dispatch(setRulesLoading());
        return axios.post(API_URL+'/api/rules/'+id+'/create', { title, rule, parent_rule_id })
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(setRules(res.data.rules));
                    dispatch(ruleFormReset());
                    dispatch(closeRulesCreate());
                }
                else {
                    dispatch(ruleFormError(res.data.message));
                }
            })
            .catch(function(err) {
                
                // token is likely invalid; remove it and prompt the user to login again
                console.log(err);
                dispatch(ruleFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const updateRule = (id, rid, { title, rule } = {}) => {
    
    return (dispatch) => {

        dispatch(setRulesLoading());
        return axios.put(API_URL+'/api/rules/'+id+'/'+rid, { title, rule })
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(setRules(res.data.rules));
                    dispatch(ruleFormSuccess('Rule Updated!'));
                }
                else {
                    dispatch(ruleFormError(res.data.message));
                    console.log(res.data);
                    console.log("Could not add your rule!");
                }
            })
            .catch(function(err) {

                // token is likely invalid; remove it and prompt the user to login again
                dispatch(ruleFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const removeRule = (rid, id) => {

    return (dispatch) => {

        dispatch(setRulesLoading());
        return axios.delete(API_URL+'/api/rules/'+id+'/'+rid, {})
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(setRules(res.data.rules));
                }
                else {
                    // dispatch(ruleFormError(res.data.message));
                    console.log(res.data);
                    console.log("Could not remove your rule!");
                }
            })
            .catch(function(err) {

                // token is likely invalid; remove it and prompt the user to login again
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
}