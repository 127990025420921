const matchupFormDefaultState = {
    round_id: 0,
    name: '',
    home_team_id: 0,
    away_team_id: 0,
    message: '',
    error: '',
};

export default (state = matchupFormDefaultState, action) => {
    
    switch (action.type) {

        case 'MATCHUP_FORM_UPDATE':
            return {
                ...state,
                [action.field.prop]: action.field.value
            };

        case 'MATCHUP_FORM_ERROR':
            return {
                ...state,
                message: '',
                error: action.message
            }

        case 'MATCHUP_FORM_SUCCESS':
            return {
                ...state,
                error: '',
                message: action.message
            }

        case 'MATCHUP_FORM_RESET':
            return matchupFormDefaultState;

        default:
            return state;
    }
};