// the default list of games, which is empty
const gamesDefaultState = {
    games: [],
    loading: true
};

export default (state = gamesDefaultState, action) => {
    switch (action.type) {

        case 'SET_GAMES_LOADING':
            return {
                ...state,
                loading: true
            };

        case 'LOAD_GAMES':
            return {
                ...state,
                games: action.games,
                loading: false
            };

        default:
            return state;
    }
};