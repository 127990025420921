import React from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from '@material-ui/core/Icon';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

import GridItem from "./common/GridItem";
import GridContainer from "./common/GridContainer";
import Table from "./common/Table";
import Card from "./common/Card";
import CardHeader from "./common/CardHeader";
import CardBody from "./common/CardBody";
import verifyLogin from '../utils/verifyLogin';
import validateEmail from '../utils/validateEmail';
import { removeRule } from '../actions/rules';
import { ruleFormUpdate, ruleFormError, ruleFormReset } from '../actions/ruleForm';
import { openRulesCreate, closeRulesCreate, openRulesUpdate, closeRulesUpdate } from '../actions/modals';
import { setSelectedLeagueID } from '../actions/selectedLeague';


// import setAuthToken from '../utils/setAuthToken';

const styles = {
    cardCategoryWhite: {
      "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
      },
      "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
      }
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
      }
    }
};

class RulesListItem extends React.Component {

    constructor(props) {
        super(props);

        this.removeRule = this.removeRule.bind(this);
        this.toggleSubruleCreateModal = this.toggleSubruleCreateModal.bind(this);
    }

    toggleSubruleCreateModal(isOpen, rid) {
        this.props.dispatch(ruleFormUpdate({ prop: 'parent_rule_id', value: rid }));
        if (isOpen) {
            this.props.dispatch(openRulesCreate());
        }
        else {
            this.props.dispatch(closeRulesCreate());
        }
    }

    removeRule({ id }) {
        if (confirm("Are you sure you want to remove this rule? This cannot be undone")) {
            this.props.dispatch(removeRule(id, this.props.match.params.id));
        };
    }
    
    render() {
        return (
            <Card className="rules-list-item">
                <CardBody>
                    <h2>{this.props.rule.title}</h2>
                    <p className="rule-text">{this.props.rule.rule}</p>

                    <div className="rules-list-item-util">
                        <Fab 
                            size="small" 
                            color="secondary"
                            className="rules-list-item-fab btn-edit"
                            onClick={() => { this.props.toggleUpdateModal(true, this.props.rule) }}
                        >
                            <EditIcon />
                        </Fab>

                        <Fab 
                            size="small" 
                            color="secondary"
                            className="rules-list-item-fab btn-add"
                            onClick={() => this.toggleSubruleCreateModal(true, this.props.rule.id)}
                        >
                            <AddIcon />
                        </Fab>

                        <Fab 
                            size="small" 
                            color="secondary"
                            className="rules-list-item-fab btn-remove"
                            onClick={() => { this.removeRule(this.props.rule) }}
                        >
                            <DeleteIcon />
                        </Fab>
                    </div>

                    {this.props.rule.subRules.map((subrule) => (
                        <Card key={"subrule-"+subrule.id}>
                            <CardBody>
                                <h3>{subrule.title}</h3>
                                <p class="subrule-text">{subrule.rule}</p>

                                <div className="rules-list-item-util">
                                <Fab 
                                    size="small" 
                                    color="secondary"
                                    className="rules-list-item-fab btn-edit"
                                    onClick={() => { this.props.toggleUpdateModal(true, subrule) }}
                                >
                                    <EditIcon />
                                </Fab>

                                <Fab 
                                    size="small" 
                                    color="secondary"
                                    className="rules-list-item-fab btn-remove"
                                    onClick={() => { this.removeRule(subrule) }}
                                >
                                    <DeleteIcon />
                                </Fab>
                                </div>
                            </CardBody>
                        </Card>

                    ))}
                </CardBody>
            </Card>
        );
    }
};

const mapStateToProps = (state) => {

    return {
        loading: state.members.loading,
        // createModalOpen: state.modals.rulesCreate,
        // updateModalOpen: state.modals.rulesUpdate,
    };
};

export default connect(mapStateToProps)(withStyles(styles)(RulesListItem));
