// the default list of divisions, which is empty
const playoffsDefaultState = {
    rounds: [],
    games: [],
    selectedDivisionTeams: [],
    loading: true
};

export default (state = playoffsDefaultState, action) => {
    switch (action.type) {

        case 'SET_PLAYOFFS_LOADING':
            return {
                ...state,
                loading: true
            };

        case 'LOAD_SELECTED_DIVISION_TEAMS':
            return {
                ...state,
                selectedDivisionTeams: action.teams,
                loading: false
            };

        case 'LOAD_PLAYOFF_GAMES':
            return {
                ...state,
                games: action.games,
                loading: false
            };

        case 'LOAD_PLAYOFFS':
            return {
                ...state,
                rounds: action.rounds,
                loading: false
            };

        default:
            return state;
    }
};