// Get games with outstanding scores

export default (games, game_date, filterTeam) => {

    console.log('filterTeam');
    console.log(filterTeam);

    return games.filter((theGame) => {     

        const gameTestDate = new Date(theGame.game_time);

        if (filterTeam != "") {
            return (game_date >= gameTestDate && theGame.is_played == 0 && (theGame.away_team_id == filterTeam || theGame.home_team_id == filterTeam));
        }
        else {
            return (game_date >= gameTestDate && theGame.is_played == 0);
        }

    }).sort((a, b) => {

        return a.game_time > b.game_time ? 1 : -1;
    });
};