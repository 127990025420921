import React from "react";
import { connect } from 'react-redux';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from '@material-ui/core/Icon';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

import GridItem from "./common/GridItem";
import GridContainer from "./common/GridContainer";
import Table from "./common/Table";
import Card from "./common/Card";
import CardHeader from "./common/CardHeader";
import CardBody from "./common/CardBody";
import verifyLogin from '../utils/verifyLogin';
import validateEmail from '../utils/validateEmail';
import { getLeagueMembers, addMember, updateMember, removeMember } from '../actions/members';
import { memberFormUpdate, memberFormError, memberFormReset } from '../actions/memberForm';
import { openMembersCreate, closeMembersCreate, openMembersUpdate, closeMembersUpdate } from '../actions/modals';
import { setSelectedLeagueID } from '../actions/selectedLeague';

const styles = {
    cardCategoryWhite: {
      "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
      },
      "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
      }
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
      }
    }
  };


class UsersIndexPage extends React.Component {

    constructor(props) {
        super(props);

        this.toggleCreateModal = this.toggleCreateModal.bind(this);
        this.toggleUpdateModal = this.toggleUpdateModal.bind(this);
        this.submitNewCommitteeMember = this.submitNewCommitteeMember.bind(this);
        this.updateCommitteeMemberRole = this.updateCommitteeMemberRole.bind(this);
        this.removeMember = this.removeMember.bind(this);

        this.state = {
            selectedMember: {}
        };
    }

    removeMember({ id }) {
        if (confirm("Are you sure you want to remove this committee member? This cannot be undone")) {
            this.props.dispatch(removeMember(id, this.props.match.params.id));
        };
    }

    componentDidMount() {
        this.props.dispatch(setSelectedLeagueID(this.props.match.params.id));
        verifyLogin(this.props.history);

        this.props.dispatch(getLeagueMembers(this.props.match.params.id));
    }

    componentDidUpdate() {
        verifyLogin(this.props.history);
    }

    toggleCreateModal(isOpen) {
        if (isOpen) {
            this.props.dispatch(openMembersCreate());
        }
        else {
            this.props.dispatch(closeMembersCreate());
        }
    }

    toggleUpdateModal(isOpen, selectedMember) {
        if (isOpen) {
            this.setState(() => ({
                selectedMember
            }));
            this.props.dispatch(memberFormReset());
            this.props.dispatch(memberFormUpdate({ prop: 'role', value: selectedMember.role }));
            this.props.dispatch(openMembersUpdate());
        }
        else {
            this.props.dispatch(closeMembersUpdate());
        }
    }

    submitNewCommitteeMember() {

        // perform some error checking
        if (this.props.memberForm.email.trim() == "" || this.props.memberForm.role.trim() == "") {

            // required fields aren't present
            this.props.dispatch(memberFormError("Please enter an email address and member role."));
        }
        else if (!validateEmail(this.props.memberForm.email.trim())) {

            // required fields aren't present
            this.props.dispatch(memberFormError("Please enter a valid email address."));
        }
        else {

            // add the committee member
            this.props.dispatch(addMember(this.props.match.params.id, this.props.memberForm));
        }
    }

    updateCommitteeMemberRole() {

        // perform some error checking
        if (this.props.memberForm.role.trim() == "") {

            // required fields aren't present
            this.props.dispatch(memberFormError("Please enter a valid member role."));
        }
        else {

            // add the committee member
            this.props.dispatch(updateMember(this.props.match.params.id, this.state.selectedMember.id, this.props.memberForm));
        }
    }

    renderCreateModal() {
        const { classes } = this.props;

        return (
            <Modal
                aria-labelledby="league-member-add"
                aria-describedby="league-member-add"
                open={this.props.createModalOpen}
                onClose={() => this.toggleCreateModal(false)}
                className="modal_admin"
            >
                <div className="modal-member">
                    <Typography variant="h4" className="modal-title">
                        Add a Committee Member
                    </Typography>
                    <Typography className="modal-description">
                        Please add the email of the user that you want to add, and the role that you want to assign to them.
                    </Typography>

                    { this.props.memberForm.error && (
                        <Typography className="modal-error">
                            { this.props.memberForm.error }
                        </Typography>
                    )}

                    <TextField
                        id="standard-name"
                        label="Email Address"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.memberForm.email}
                        onChange={(e) => { this.props.dispatch(memberFormUpdate({ prop: 'email', value: e.target.value })); }}
                        margin="normal"
                    />

                    <FormControl className="form-control">
                        <InputLabel htmlFor="new-user-role">Role</InputLabel>
                        <Select
                            value={this.props.memberForm.role}
                            onChange={(e) => { this.props.dispatch(memberFormUpdate({ prop: 'role', value: e.target.value })); }}
                            fullWidth={true}
                            className="modal-select"
                            inputProps={{
                                name: 'role',
                                id: 'new-user-role',
                            }}
                        >
                            <MenuItem value="">
                                Select a Role
                            </MenuItem>
                            <MenuItem value={'admin'}>Administrator</MenuItem>
                            <MenuItem value={'manager_all'}>Schedule/Team Manager</MenuItem>
                            <MenuItem value={'manager_schedule'}>Schedule Manager</MenuItem>
                            <MenuItem value={'manager_team'}>Team Manager</MenuItem>
                            <MenuItem value={'coach'}>Coach</MenuItem>
                        </Select>
                    </FormControl>

                    <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.submitNewCommitteeMember}>
                        Add Member
                    </Button>
                </div>
            </Modal>
        )
    }

    renderUpdateModal() {
        return (
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={this.props.updateModalOpen}
                onClose={() => this.toggleUpdateModal(false)}
                className="modal_admin"
            >
                <div className="modal-member">
                    <Typography variant="h4" className="modal-title">
                        Update {this.state.selectedMember.fullName}&apos;s Role
                    </Typography>
                    <Typography className="modal-description">
                        Please select the updated user role, and click the update button to continue.
                    </Typography>

                    { this.props.memberForm.error && (
                        <Typography className="modal-error">
                            { this.props.memberForm.error }
                        </Typography>
                    )}

                    { this.props.memberForm.message && (
                        <Typography className="modal-success">
                            { this.props.memberForm.message }
                        </Typography>
                    )}

                    <FormControl className="form-control">
                        <InputLabel htmlFor="new-user-role">Role</InputLabel>
                        <Select
                            value={this.props.memberForm.role}
                            onChange={(e) => { this.props.dispatch(memberFormUpdate({ prop: 'role', value: e.target.value })); }}
                            fullWidth={true}
                            className="modal-select"
                            inputProps={{
                                name: 'role',
                                id: 'new-user-role',
                            }}
                        >
                            <MenuItem value="">
                                Select a Role
                            </MenuItem>
                            <MenuItem value={'admin'}>Administrator</MenuItem>
                            <MenuItem value={'manager_all'}>Schedule/Team Manager</MenuItem>
                            <MenuItem value={'manager_schedule'}>Schedule Manager</MenuItem>
                            <MenuItem value={'manager_team'}>Team Manager</MenuItem>
                            <MenuItem value={'coach'}>Coach</MenuItem>
                        </Select>
                    </FormControl>

                    <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.updateCommitteeMemberRole}>
                        Update Role
                    </Button>
                </div>
            </Modal>
        )
    }

    printRole(theRole) {
        switch(theRole) {

            case "owner":
                return "League Owner";

            case "admin":
                return "Administrator";

            case "manager_schedule":
                return "Schedule Manager";

            case "manager_all":
                return "Schedule/Team Manager";

            case "manager_team":
                return "Team Manager";
            
            case "coach":
                return "Coach";

            default: 
                return theRole;
        }
    }

    printStatus(theStatus) {
        if (theStatus == "confirmed") {
            return "Confirmed";
        }
        else if (theStatus == "pending") {
            return "Pending";
        }
        else {
            return theStatus;
        }
    }

    renderMembersList() {
        const { classes } = this.props;

        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="primary" className="card-header">
                            <h4 className={classes.cardTitleWhite}>{this.props.league.name}</h4>
                            <p className={classes.cardCategoryWhite}>
                            Committee Member Management
                            </p>
                        </CardHeader>
                        <CardBody>
                            <Table
                                tableHead={["Name", "Email", "Role", "Status", "Actions"]}
                                tableData={this.props.members.map((member) => [
                                    member.fullName, 
                                    member.emailAddress, 
                                    this.printRole(member.role),
                                    this.printStatus(member.roleStatus),
                                    (
                                        <div>
                                            {(member.role != "owner") && <Icon className="fa fa-pen manager-table-icon manager-table-edit" onClick={() => this.toggleUpdateModal(true, member)} />}
                                            {(member.role != "owner") && <Icon className="fa fa-trash manager-table-remove" onClick={() => this.removeMember(member)} />}
                                        </div>
                                    )
                                ])}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    }
    
    render() {
        return (
            <div>
                {this.renderMembersList()}

                <Fab className="btn-fab btn-add" onClick={() => this.toggleCreateModal(true)}>
                    <AddIcon />
                </Fab>

                {this.renderCreateModal()}
                {this.renderUpdateModal()}
            </div>
        );
    }
};

const mapStateToProps = (state) => {

    return {
        isAuthenticated: state.auth.isAuthenticated,
        loading: state.members.loading,
        members: state.members.members,
        memberForm: state.memberForm,
        createModalOpen: state.modals.membersCreate,
        updateModalOpen: state.modals.membersUpdate,
        league: state.selectedLeague.league
    };
};

export default connect(mapStateToProps)(withStyles(styles)(UsersIndexPage));
