// the default list of fields, which is empty
const fieldsDefaultState = {
    fields: [],
    loading: true
};

export default (state = fieldsDefaultState, action) => {
    switch (action.type) {

        case 'SET_FIELDS_LOADING':
            return {
                ...state,
                loading: true
            };

        case 'SET_FIELDS':
            return {
                ...state,
                fields: action.fields,
                loading: false
            };

        default:
            return state;
    }
};