import isEmpty from 'lodash/isEmpty';

// the default items that we can possibly filter by
const authDefaultState = {
    isAuthenticated: false,
    user: {}
};

export default (state = authDefaultState, action) => {
    switch (action.type) {
        case 'SET_CURRENT_USER':
            return {
                ...state,
                isAuthenticated: !isEmpty(action.user),
                user: action.user
            };
            
        case 'REMOVE_CURRENT_USER':
            return authDefaultState;

        default:
            return state;
    }
};